import { convertFromRaw, SelectionState, EditorState, Modifier } from "draft-js";

import * as types from "./draft.types.js";
import { DOCUMENT_LOADED, APP_UNLOADED } from "./editor.types.js";
import {
  removeBlock,
  insertMergeField,
  replaceEntitiesWithCRMValues,
  insertPastedContent,
  insertImage,
} from "./draft.helper.js";

const DEFAULT_STATE = EditorState.createEmpty();

export default function draftReducer(state = DEFAULT_STATE, action) {
  if (action.type === types.BOILERPLATE_TEXT_INSERTED) {
    let selectionState = state.getSelection();

    if (selectionState.getAnchorOffset() !== selectionState.getFocusOffset()) {
      return state;
    }

    let contentState = Modifier.replaceText(
      state.getCurrentContent(),
      selectionState,
      action.textToInsert,
      state.getCurrentInlineStyle()
    );

    selectionState = selectionState
      .set("anchorOffset", selectionState.getAnchorOffset() + action.textToInsert.length)
      .set("focusOffset", selectionState.getFocusOffset() + action.textToInsert.length);

    return EditorState.forceSelection(
      EditorState.createWithContent(contentState, state.getDecorator()),
      selectionState
    );
  }

  if (action.type === types.BLOCK_REMOVED) {
    return removeBlock(action, state, action.SelectionState || SelectionState);
  }

  if (action.type === types.MERGE_FIELD_INSERTED) {
    return insertMergeField(action, state);
  }

  if (action.type === types.CONTENT_PASTED) {
    return insertPastedContent(action.content, state);
  }

  if (action.type === types.UPDATED_EDITOR_STATE) {
    return action.editorState;
  }

  if (action.type === DOCUMENT_LOADED) {
    // If there is a raw body we are loading a previous letter/template
    if (action.rawBody) {
      // If there are merge field values, we are loading a letter
      if (action.mergeFieldValues) {
        const rawContentState = JSON.parse(action.rawBody);
        return EditorState.createWithContent(
          replaceEntitiesWithCRMValues(convertFromRaw(rawContentState), rawContentState, action.mergeFieldValues)
        );
      } else {
        // We are loading a template
        return EditorState.createWithContent(convertFromRaw(JSON.parse(action.rawBody)));
      }
    } else {
      return EditorState.createEmpty();
    }
  }

  if (action.type === types.IMAGE_INSERTED) {
    return insertImage(action, state);
  }

  if (action.type === APP_UNLOADED) {
    return DEFAULT_STATE;
  }

  return state;
}
