// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-secondary-nav-text-styling-toolbar-styles__fontSizeMenuItem {\n  padding: 4px 28px 4px 12px!important;\n  color: rgb(119, 119, 119) !important;\n  font-weight: 400 !important;\n  font-size: 10pt !important;\n}\n\n.cps-dropdown .src-secondary-nav-text-styling-toolbar-styles__gray {\n  color: #bbbbbb!important;\n}\n", ""]);
// Exports
exports.locals = {
	"fontSizeMenuItem": "src-secondary-nav-text-styling-toolbar-styles__fontSizeMenuItem",
	"gray": "src-secondary-nav-text-styling-toolbar-styles__gray"
};
module.exports = exports;
