// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-editor-flash-icon-styles__flashIconEmbiggened {\n  height: 40px !important;\n  width: 40px !important;\n  border-radius: 20px !important;\n  left: -8px;\n  top: -9px;\n  box-shadow: 0px 3px 5px rgba(0,0,0,.18);\n  padding: 1px 0 0 2px;\n}", ""]);
// Exports
exports.locals = {
	"flashIconEmbiggened": "src-editor-flash-icon-styles__flashIconEmbiggened"
};
module.exports = exports;
