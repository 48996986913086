import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { partial } from "lodash";

import * as editorActions from "./editor.actions.js";
import * as previewActions from "../preview/preview.actions.js";

import EditorComponent from "./editor.component.js";

@connect((state) => ({
  letterEditorState: state.letterEditor,
  preview: state.preview,
}))
export default class TemplateEditorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.useCanopyTemplateEditor = props.match.path.includes("edit/canopy-template");
    this.actions = {
      ...bindActionCreators(editorActions, props.dispatch),
      ...bindActionCreators(previewActions, props.dispatch),
      deleteDocument: bindActionCreators(
        partial(
          this.useCanopyTemplateEditor ? editorActions.deleteCanopyTemplate : editorActions.deleteTemplate,
          props.match.params.templateId
        ),
        props.dispatch
      ),
      saveDocument: bindActionCreators(
        this.useCanopyTemplateEditor ? editorActions.saveCanopyTemplate : editorActions.saveTemplate,
        props.dispatch
      ),
    };
  }

  render() {
    return <EditorComponent {...this.props} actions={this.actions} />;
  }

  componentDidMount() {
    const actions = this.actions;

    actions.loadBoilerplateText();
    actions.loadMergeFields();
    if (this.useCanopyTemplateEditor) {
      actions.loadCanopyTemplate(this.props.match.params.templateId);
    } else {
      actions.loadTemplate(this.props.match.params.templateId);
    }
  }
}
