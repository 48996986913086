import React from "react";

import styles from "./client-request-reminders.styles.css";
import ReminderPicker from "./reminder-picker.component.js";

export default function ClientRequestReminders({ actions, clientRequest }) {
  const reminderMessage = {
    weekday: "Weekdays",
    "every-mwf": "Mon, Wed, Fri",
    "every-tth": "Tue, Thu",
    "every-m": "Mondays",
  };
  const hasValue = clientRequest.savedReminders.days;
  const getColor = () => {
    return !hasValue && "var(--cps-color-cool-gray)"; // will default to cps-color-primary-text
  };

  return (
    <div className={`${styles.reminders}`}>
      <div className={`${styles.remindersLeft}`}>
        <div className={`${styles.remindersLabel}`}>Reminders:</div>
      </div>
      <div style={{ width: "70%" }}>
        <div
          onClick={actions.toggleReminderMenu.bind(null, true)}
          className={`${styles.dottedUnderline}`}
          style={{ marginTop: "6px", color: getColor(), fontStyle: !hasValue && "italic" }}
        >
          {reminderMessage[hasValue] || "Add a reminder"}
        </div>
        <button
          onClick={actions.removeReminder}
          className="cps-link"
          style={{ marginRight: "-12px", float: "right", visibility: hasValue ? "visible" : "hidden" }}
        >
          REMOVE
        </button>
        {clientRequest.reminderModalDisplayed ? (
          <ReminderPicker
            actions={actions}
            reminderDays={clientRequest.reminderDays}
            reminderPeriod={clientRequest.reminderPeriod}
          />
        ) : (
          <span />
        )}
      </div>
    </div>
  );
}
