import React from "react";

export default class EditBoilerplate extends React.Component {
  constructor(props) {
    super();
    this.state = {
      title: props.boilerplate ? props.boilerplate.title : "",
      body: props.boilerplate ? props.boilerplate.body : "",
    };
    this.updateTitle = (e) => this.setState({ title: e.target.value });
    this.updateBody = (e) => this.setState({ body: e.target.value });
  }

  save() {
    const boilerplate = this.props.boilerplate || {};
    this.props.save({
      ...boilerplate,
      ...this.state,
    });
  }

  render() {
    const { boilerplate, close } = this.props;
    const { title, body } = this.state;

    return (
      <div className="cps-modal">
        <div className="cps-modal__screen"></div>

        <div className="cps-modal__dialog cps-card" style={{ width: 600 }}>
          <div className="cps-card__header cps-subheader-sm">
            {!boilerplate && <span>Add your Boilerplate</span>}
            {boilerplate && <span>Edit your Boilerplate</span>}
            <a onClick={close} className="cps-modal__dialog__close cps-icon cps-icon-close"></a>
          </div>
          <div className="cps-card__body">
            <form name="boilerplateForm" autoComplete="off">
              <div className="cps-form-group">
                <label>Boilerplate Title</label>
                <input
                  onChange={this.updateTitle}
                  value={title}
                  name="title"
                  type="text"
                  className="cps-form-control"
                  maxLength="60"
                />
              </div>
              <div className="cps-form-group" style={{ marginBottom: 0 }}>
                <label>Boilerplate Content</label>
                <textarea
                  onChange={this.updateBody}
                  value={body}
                  name="content"
                  className="cps-form-control"
                  style={{ minHeight: 140, resize: "vertical" }}
                ></textarea>
              </div>
            </form>
          </div>
          <div className="cps-padding-left-24 cps-padding-bottom-24 cps-padding-top-16">
            {!boilerplate && (
              <button onClick={this.save.bind(this)} className="cps-btn +primary" disabled={!title || !body}>
                Add boilerplate
              </button>
            )}
            {boilerplate && (
              <button onClick={this.save.bind(this)} className="cps-btn +primary" disabled={!title || !body}>
                Save changes
              </button>
            )}
            <a onClick={close} className="cps-link">
              Cancel
            </a>
          </div>
        </div>
      </div>
    );
  }
}
