export const CLIENT_REQUEST_LOADED = "CLIENT_REQUEST_LOADED";
export const CLIENT_REQUEST_SENT = "CLIENT_REQUEST_SENT";
export const CLIENT_REQUEST_CREATED = "CLIENT_REQUEST_CREATED";
export const CLIENT_REQUEST_SAVED = "CLIENT_REQUEST_SAVED";
export const EMPTY_CLIENT_REQUEST_LOADED = "EMPTY_CLIENT_REQUEST_LOADED";
export const CLIENT_REQUEST_ATTACHMENT_LOADED = "CLIENT_REQUEST_ATTACHMENT_LOADED";
export const GOT_CLIENT_USERS = "GOT_CLIENT_USERS";

export const CLIENT_REQUEST_DIALOG_CLOSED = "CLIENT_REQUEST_DIALOG_CLOSED";
export const ANIMATE_SHOW_TOGGLED = "ANIMATE_SHOW_TOGGLED";

export const REQUEST_TITLE_CHANGED = "REQUEST_TITLE_CHANGED";
export const REMINDER_MODAL_TOGGLED = "REMINDER_MODAL_TOGGLED";
export const REMINDER_DAYS_SET = "REMINDER_DAYS_SET";
export const REMINDER_PERIOD_SET = "REMINDER_PERIOD_SET";
export const REMINDER_SAVED = "REMINDER_SAVED";
export const UPDATED_REQUEST_EDITOR_STATE = "UPDATED_REQUEST_EDITOR_STATE";
export const REMINDER_REMOVED = "REMINDER_REMOVED";
export const DUE_DATE_SET = "DUE_DATE_SET";
export const DUE_DATE_REMOVED = "DUE_DATE_REMOVED";
