import { EditorState, Modifier } from "draft-js";
import invariant from "invariant";

export default function moveAtomicBlock(editorState, atomicBlock, targetRange, insertionMode) {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  let withMovedAtomicBlock;

  if (insertionMode === "before" || insertionMode === "after") {
    const targetBlock = contentState.getBlockForKey(
      insertionMode === "before" ? targetRange.getStartKey() : targetRange.getEndKey()
    );

    withMovedAtomicBlock = moveBlockInContentState(contentState, atomicBlock, targetBlock, insertionMode);
  } else {
    const afterRemoval = Modifier.removeRange(contentState, targetRange, "backward");

    const selectionAfterRemoval = afterRemoval.getSelectionAfter();
    const targetBlock = afterRemoval.getBlockForKey(selectionAfterRemoval.getFocusKey());

    if (selectionAfterRemoval.getStartOffset() === 0) {
      withMovedAtomicBlock = moveBlockInContentState(afterRemoval, atomicBlock, targetBlock, "before");
    } else if (selectionAfterRemoval.getEndOffset() === targetBlock.getLength()) {
      withMovedAtomicBlock = moveBlockInContentState(afterRemoval, atomicBlock, targetBlock, "after");
    } else {
      const afterSplit = Modifier.splitBlock(afterRemoval, selectionAfterRemoval);

      const selectionAfterSplit = afterSplit.getSelectionAfter();
      const targetBlock = afterSplit.getBlockForKey(selectionAfterSplit.getFocusKey());

      withMovedAtomicBlock = moveBlockInContentState(afterSplit, atomicBlock, targetBlock, "before");
    }
  }

  const newContent = withMovedAtomicBlock.merge({
    selectionBefore: selectionState,
    selectionAfter: withMovedAtomicBlock.getSelectionAfter().set("hasFocus", true),
  });

  return EditorState.push(editorState, newContent, "move-block");
}

function moveBlockInContentState(contentState, blockToBeMoved, targetBlock, insertionMode) {
  invariant(blockToBeMoved.getKey() !== targetBlock.getKey(), "Block cannot be moved next to itself.");

  invariant(insertionMode !== "replace", "Replacing blocks is not supported.");

  const targetKey = targetBlock.getKey();
  const blockBefore = contentState.getBlockBefore(targetKey);
  const blockAfter = contentState.getBlockAfter(targetKey);

  const blockMap = contentState.getBlockMap();
  const blockMapWithoutBlockToBeMoved = blockMap.delete(blockToBeMoved.getKey());
  const blocksBefore = blockMapWithoutBlockToBeMoved.toSeq().takeUntil((v) => v === targetBlock);
  const blocksAfter = blockMapWithoutBlockToBeMoved
    .toSeq()
    .skipUntil((v) => v === targetBlock)
    .skip(1);

  let newBlocks;

  if (insertionMode === "before") {
    invariant(
      !blockBefore || blockBefore.getKey() !== blockToBeMoved.getKey(),
      "Block cannot be moved next to itself."
    );

    newBlocks = blocksBefore
      .concat(
        [
          [blockToBeMoved.getKey(), blockToBeMoved],
          [targetBlock.getKey(), targetBlock],
        ],
        blocksAfter
      )
      .toOrderedMap();
  } else if (insertionMode === "after") {
    invariant(!blockAfter || blockAfter.getKey() !== blockToBeMoved.getKey(), "Block cannot be moved next to itself.");

    newBlocks = blocksBefore
      .concat(
        [
          [targetBlock.getKey(), targetBlock],
          [blockToBeMoved.getKey(), blockToBeMoved],
        ],
        blocksAfter
      )
      .toOrderedMap();
  }

  return contentState.merge({
    blockMap: newBlocks,
    selectionBefore: contentState.getSelectionAfter(),
    selectionAfter: contentState.getSelectionAfter().merge({
      anchorKey: blockToBeMoved.getKey(),
      focusKey: blockToBeMoved.getKey(),
    }),
  });
}
