import React from "react";

import canopyUrls from "canopy-urls!sofe";
import styles from "./client-request-file.styles.css";

export default function ClientRequestFile({ downloadParams, filename, filesize }) {
  return (
    <div className={`${styles.file}`}>
      <div className={`${styles.fileLeft}`}>
        <img
          src="https://cdn.canopytax.com/static/workflow-ui/file_icons/pdf_icon.png"
          className={`${styles.fileIcon}`}
        />
        <div className={`${styles.fileDetails}`}>
          <div className={`cps-body-sm cps-wt-semibold ${styles.fileName}`}>{filename || "Untitled"}</div>
          <div className={`cps-caption ${styles.fileSize}`}>{filesize === "" ? "" : `Size: ${filesize}`}</div>
        </div>
      </div>
      <div className={`cps-btn-icon ${styles.fileActions}`}>
        <div className={`${styles.fileActionsIcons}`}>
          <a
            className="cps-link"
            download={filename || "Untitled"}
            href={buildDownloadURL(downloadParams.clientId, downloadParams.resolutionCaseId, downloadParams.letterId)}
          >
            <span className="cps-icon cps-icon-download"></span>
          </a>
        </div>
      </div>
    </div>
  );
}

function buildDownloadURL(clientId, resolutionCaseId, letterId) {
  return `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/letters/${letterId}/pdf`;
}
