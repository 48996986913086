import { convertToRaw } from "draft-js";
import * as types from "./draft.types.js";
import * as editorTypes from "./editor.types.js";
import { hasUnmergedTags } from "./draft.helper.js";

const DEFAULT_STATE = true;

export default function isSavedReducer(state = DEFAULT_STATE, action, editorState) {
  const { type } = action;

  if (
    state &&
    (type === types.BOILERPLATE_TEXT_INSERTED ||
      type === types.BLOCK_REMOVED ||
      type === types.MERGE_FIELD_INSERTED ||
      type === types.UPDATED_EDITOR_STATE ||
      type === types.IMAGE_INSERTED) &&
    !stateEqual(editorState.old, editorState.fresh)
  ) {
    //Marks the editor as dirty - unsaved changes
    return false;
  }

  if (state && type === "DOCUMENT_LOADED" && action.documentType === "letter" && hasUnmergedTags(action.rawBody)) {
    //Marks the editor as dirty - merge tags values need to be saved
    return false;
  }

  if (type === editorTypes.DOCUMENT_SAVED) {
    //Marks the editor as clean - all changes are saved
    return true;
  }

  if (type === editorTypes.APP_UNLOADED) {
    return DEFAULT_STATE;
  }

  return state;
}

function stateEqual(old, fresh) {
  if (!old || !fresh) return false;
  const oldData = JSON.stringify(convertToRaw(old.getCurrentContent()));
  const newData = JSON.stringify(convertToRaw(fresh.getCurrentContent()));
  return oldData === newData;
}
