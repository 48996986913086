import React from "react";
import { groupBy, has, includes } from "lodash";

import InsertTextMenu from "./insert-text-menu.component.js";

import styles from "./tool-menu-left.styles.css";

export default function LeftToolMenu({
  actions,
  leftMenuToDisplay,
  boilerplateText,
  mergeFields,
  mergeFieldValues,
  documentType,
  editorInstance,
}) {
  let mergeFieldsData = formatMergeFields(mergeFields, mergeFieldValues, documentType);
  let boilerplateTextData = formatBoilerplates(boilerplateText);

  // formatting the data here
  let menus = {
    boilerplate: (
      <InsertTextMenu
        actions={actions}
        title="Boilerplate"
        data={boilerplateTextData}
        editorInstance={editorInstance}
      />
    ),
    "client-attributes": (
      <InsertTextMenu
        selectedTool={leftMenuToDisplay}
        documentType={documentType}
        actions={actions}
        title="Attributes"
        data={mergeFieldsData}
        editorInstance={editorInstance}
        mergeFields={mergeFields}
      />
    ),
  };

  return (
    <div className={`cps-btn-icon ${styles.sidePane}`} style={{ left: leftMenuToDisplay ? "8rem" : "-16.4rem" }}>
      <div className={`${styles.sidePaneContainer}`}>{menus[leftMenuToDisplay]}</div>
    </div>
  );
}

function formatBoilerplates(boilerplateData) {
  if (!boilerplateData) {
    return null;
  }

  let list = boilerplateData.reduce((all, category) => {
    //Skip the category if it is empty
    if (!has(category, "relationships.boilerplates")) {
      return all;
    }

    let items = category.relationships.boilerplates.map((boilerplate) => ({
      category: category.title,
      categoryKey: category.id,
      key: boilerplate.id,
      title: boilerplate.title,
      insertText: boilerplate.body,
      tooltip: boilerplate.body,
    }));

    return all.concat(items);
  }, []);

  return groupBy(list, "categoryKey");
}

function formatMergeFields(mergeFields, mergeFieldValues, documentType) {
  if (!mergeFields) {
    return null;
  }

  const addresses = [
    "home-address",
    "mailing-address",
    "work-address",
    "physical-address",
    "other-address",

    "spouse-home-address",
    "spouse-mailing-address",
    "spouse-work-address",
    "spouse-physical-address",
    "spouse-other-address",
  ];

  const list = mergeFields.map((item) => {
    const categoryKey = item.group.replace(/\s+/g, "");

    let mergeField = {
      category: item.group,
      categoryKey: categoryKey,
      key: item.key,
      title: item.label,
      insertText: item.placeholder || item.label,
      hidden: documentType === "letter" && includes(addresses, item.key) ? false : item.hidden,
      format: item.format,
    };

    if (mergeFieldValues) {
      mergeField.mergedValue = mergeFieldValues[item.key];
      mergeField.tooltip = mergeFieldValues[item.key];
    }

    return mergeField;
  });

  const filteredList = mergeFieldValues ? list.filter((item) => item.mergedValue) : list;

  return groupBy(filteredList, "categoryKey");
}
