import React from "react";
import { RichUtils } from "draft-js";

import { click } from "../common/common.utils.js";

import styles from "./client-request-description.styles.css";

export default function TextStylingToolbar({ actions, editorState, editorInstance }) {
  const currentInlineStyles = editorState.getCurrentInlineStyle().toObject();
  const currentBlockType = RichUtils.getCurrentBlockType(editorState);

  return (
    <div className="cps-btn-icon">
      <a onMouseDown={click.bind(null, actions.toggleInlineStyle.bind(null, editorState, "BOLD", editorInstance))}>
        <span
          className={`cps-icon-bold ${styles.descriptionToolbarBtn} ${
            currentInlineStyles.BOLD ? "cps-color-primary" : ""
          }`}
        ></span>
      </a>
      &nbsp;
      <a onMouseDown={click.bind(null, actions.toggleInlineStyle.bind(null, editorState, "ITALIC", editorInstance))}>
        <span
          style={{ paddingLeft: "0px" }}
          className={`cps-icon-italics ${styles.descriptionToolbarBtn} ${
            currentInlineStyles.ITALIC ? "cps-color-primary" : ""
          }`}
        ></span>
      </a>
      &nbsp;
      <a
        onMouseDown={click.bind(
          null,
          actions.toggleBlockType.bind(null, editorState, "unordered-list-item", editorInstance)
        )}
      >
        <span
          className={`cps-icon-list ${styles.descriptionToolbarBtn} ${
            currentBlockType === "unordered-list-item" ? "cps-color-primary" : ""
          }`}
        ></span>
      </a>
      &nbsp;
      <a
        onMouseDown={click.bind(
          null,
          actions.toggleBlockType.bind(null, editorState, "ordered-list-item", editorInstance)
        )}
      >
        <span
          className={`cps-icon-number ${styles.descriptionToolbarBtn} ${
            currentBlockType === "ordered-list-item" ? "cps-color-primary" : ""
          }`}
        ></span>
      </a>
    </div>
  );
}
