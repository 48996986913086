// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-preview-preview-styles__loadingWrapper {\n  width: 81.8rem;\n  margin: 0 2.4rem;\n  height: 105.6rem;\n  display: flex;\n  align-items: center;\n}\n\n.src-preview-preview-styles__loader {\n  margin: 0 auto;\n}\n\n.src-preview-preview-styles__previewPages {\n  width: 81.8rem;\n  margin: 0 2.4rem;\n}\n", ""]);
// Exports
exports.locals = {
	"loadingWrapper": "src-preview-preview-styles__loadingWrapper",
	"loader": "src-preview-preview-styles__loader",
	"previewPages": "src-preview-preview-styles__previewPages"
};
module.exports = exports;
