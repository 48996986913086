import React, { lazy, Suspense, useState, useEffect } from "react";
import { DateTime } from "luxon";
import { sortBy, without } from "lodash";
import { handleError } from "src/handle-error.helper.js";
import { CpArea, CpButton, CpCard, CpEmptyState, CpInlineNotification, CpLoader, CpWell } from "canopy-styleguide!sofe";
import { onPusher } from "fetcher!sofe";
import toasts from "toast-service!sofe";
import { CreateTemplate } from "./create-template.component";
import {
  createAccusoftTemplate,
  getTemplates,
  uploadDocumentTemplate,
  removeTemplate,
  updateTemplateToDocx,
} from "../editor/editor.resource";
import styles from "./templates.styles.css";

const DocumentEditorOverlay = lazy(() =>
  SystemJS.import("document-editor-ui!sofe")
    .then((deUI) => deUI.getDocumentEditorOverlay())
    .then((modal) => modal)
);

const FileUploader = lazy(() => SystemJS.import("tasks-ui!sofe").then((tasks) => tasks.getFileUploader()));

const TemplatesContainer = () => {
  const [templatesTrigger, setTemplatesTrigger] = useState(true);
  const [templates, setTemplates] = useState();
  const [template, setTemplate] = useState({});
  const [showNewTemplateModal, setShowNewTemplateModal] = useState(false);
  const [showDocumentEditor, setShowDocumentEditor] = useState(false);
  const [showFileUploader, setShowFileUploader] = useState(false);

  const hasTemplates = templates && templates.length > 0;
  const upgradeableTemplates =
    templates &&
    templates.filter((template) => {
      return template.document_template_id === null && !template.conversion_inprogress;
    });

  useEffect(() => {
    if (!templatesTrigger) return;

    const subscription = getTemplates().subscribe((response) => {
      setTemplates(response.letters);
      setTemplatesTrigger(false);
    });

    return () => subscription.unsubscribe();
  }, [templatesTrigger]);

  useEffect(() => {
    const sub = onPusher("letter_template").subscribe(() => setTemplatesTrigger(true));
    return () => sub.unsubscribe();
  }, []);

  const openNewTemplateModal = () => {
    setShowNewTemplateModal(true);
  };

  const closeNewTemplateModal = () => {
    setShowNewTemplateModal(false);
  };

  const onCreateTemplate = (newTemplate) => {
    createAccusoftTemplate(newTemplate.title).subscribe(
      (template) => {
        setShowNewTemplateModal(false);
        setTemplate({ ...template, title: newTemplate.title });
        setTemplates([...templates, { ...template, title: newTemplate.title }]);
        setShowDocumentEditor(true);
      },
      (err) => {
        setShowNewTemplateModal(false);
        handleError(err);
      }
    );
  };

  const onUploadDocument = (document) => {
    uploadDocumentTemplate(document).subscribe(() => {
      toasts.successToast("Template uploaded successfully");
      setShowFileUploader(false);
      setTemplatesTrigger(true);
    }, handleError);
  };

  const onUpdateTemplate = (templateToUpdate, e) => {
    e && e.stopPropagation();

    updateTemplateToDocx(templateToUpdate.id).subscribe(() => {
      setTemplates(
        templates.map((template) => {
          if (template.id === templateToUpdate.id) {
            template.conversion_inprogress = true;
          }
          return template;
        })
      );
    }, handleError);
  };

  const onUpdateAllTemplates = () => {
    upgradeableTemplates.forEach((template) => {
      onUpdateTemplate(template);
    });
  };

  const onDeleteTemplate = (template, e) => {
    e.stopPropagation();

    const timeout = setTimeout(() => {
      removeTemplate(template.id).subscribe(() => {}, handleError);
    }, 4000);

    const newTemplates = without(templates, template);
    setTemplates(newTemplates);

    toasts.successToast(
      `Template ${template.title} deleted`,
      "Undo",
      () => {
        clearTimeout(timeout);

        setTemplates([...newTemplates, template]);

        toasts.successToast("Template restored successfully");
      },
      4000
    );
  };

  const onOpenTemplate = (template) => {
    if (!template.document_template_id) {
      window.location = `/#/letters/edit/template/${template.id}`;
    } else {
      setTemplate(template);
      setShowDocumentEditor(true);
    }
  };

  const onCloseTemplate = () => {
    setTemplate({});
    setShowDocumentEditor(false);
    setTemplatesTrigger(true);
  };

  return (
    <>
      <CpCard>
        <CpCard.Header>
          <div className="cps-subheader-sm cps-wt-semibold">Letter Templates</div>
          <div>
            <CpButton className="cp-mr-16" btnType="secondary" onClick={() => setShowFileUploader(true)}>
              Upload .docx file
            </CpButton>
            <CpButton btnType="flat" onClick={openNewTemplateModal}>
              Create new letter template
            </CpButton>
          </div>
        </CpCard.Header>

        <CpCard.Body>
          {!templates && <CpLoader className="cp-mv-32" />}

          {templates && !hasTemplates && (
            <CpEmptyState
              img="es_templates"
              className="cp-mv-32"
              text="Add new letter template"
              subText="Letter Templates allow you to be more efficient with the letters you use everyday."
            />
          )}

          {templates && hasTemplates && (
            <>
              {!!upgradeableTemplates.length && (
                <div className="cp-mb-16">
                  <CpInlineNotification
                    icon="alert-triangle-open-large"
                    message={`You have ${upgradeableTemplates.length} template${
                      upgradeableTemplates.length > 1 ? "s" : ""
                    } that need${upgradeableTemplates.length > 1 ? "" : "s"} to be updated.`}
                    cta={
                      <CpButton btnType="primary" onClick={onUpdateAllTemplates}>
                        Update all
                      </CpButton>
                    }
                  />
                </div>
              )}
              <CpWell>
                {sortBy(templates, "title").map((template) => (
                  <CpArea
                    key={template.id}
                    disabled={template.conversion_inprogress}
                    onClick={() => onOpenTemplate(template)}
                    className={styles.template}
                  >
                    <div>
                      <div>{template.title}</div>
                      {template.relationships.updated_by &&
                        `Template last edited on ${DateTime.fromISO(template.updated_at).toLocaleString(
                          DateTime.DATE_SHORT
                        )} by ${template.relationships.updated_by.name}`}
                    </div>
                    <div className="cp-flex">
                      {template.conversion_inprogress ? (
                        <CpLoader className="cp-mr-16" />
                      ) : !template.document_template_id ? (
                        <CpButton
                          btnType="secondary"
                          className="cp-mr-16"
                          onClick={(e) => onUpdateTemplate(template, e)}
                        >
                          Update
                        </CpButton>
                      ) : null}
                      <CpButton
                        icon="crud-trash-large"
                        aria-label="Delete"
                        onClick={(e) => onDeleteTemplate(template, e)}
                      />
                    </div>
                  </CpArea>
                ))}
              </CpWell>
            </>
          )}
        </CpCard.Body>
      </CpCard>

      {showFileUploader && (
        <Suspense fallback={<CpLoader />}>
          <FileUploader
            uploadLetter={onUploadDocument}
            onClose={() => setShowFileUploader(false)}
            hidden={true}
            uploaderType={"editable_doc"}
          />
        </Suspense>
      )}

      <CreateTemplate show={showNewTemplateModal} onClose={closeNewTemplateModal} onSave={onCreateTemplate} />

      <Suspense fallback={<CpLoader />}>
        <DocumentEditorOverlay
          documentId={template.document_template_id}
          templateId={template.id}
          title={template.title}
          mode="letter_template"
          show={showDocumentEditor}
          onClose={onCloseTemplate}
        />
      </Suspense>
    </>
  );
};

export default TemplatesContainer;
