import React, { useState } from "react";
import styles from "./text-styling.styles.css";
import { click } from "../common/common.utils.js";
import { CpButton, CpModal } from "canopy-styleguide!sofe";

export default function TextStyling({ action, active, disabled, className, isEsign }) {
  const [showModal, setShowModal] = useState(false);
  const handleClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <a
        style={{ marginRight: "8px" }}
        disabled={disabled}
        className="cps-link"
        onMouseDown={isEsign ? () => handleClick() : click.bind(null, action)}
      >
        <span className={`cps-icon ${className} ${getStatusColor(active, disabled)}`}></span>
      </a>
      <CpModal width={444} show={showModal} onClose={() => setShowModal(false)}>
        <CpModal.Header title="eSign Request Unavailable" />
        <CpModal.Body>
          <p className="cp-body">
            This file utilized an older version of the letter generator. Older letters are not compatible with the
            in-letter eSign flow.
          </p>
          <p className="cp-body">To send an eSign request for this document, please take the following steps:</p>
          <ol className="cp-ml-40">
            <li>
              Click <i>Send to:</i> to send the file to <i>Client file.</i>
            </li>
            <li>Locate the file in the client's folder.</li>
            <li>
              Right click on the file and select <i>eSign Request</i>
            </li>{" "}
            from the popup menu.
          </ol>
        </CpModal.Body>
        <p className="cp-m-12 cp-caption">
          For detailed instructions, please visit the{" "}
          <a href="https://support.getcanopy.com/hc/en-us" target="_blank" rel="noreferrer noopener">
            knowledge base
          </a>
        </p>
        <CpModal.Footer>
          <CpButton btnType="primary" onClick={() => setShowModal(false)}>
            Close
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    </>
  );
}

function getStatusColor(active, disabled) {
  if (disabled) return styles.gray;
  if (active) return "cps-color-primary";
  return "cps-color-cool-gray";
}
