import React from "react";
import { sortBy, findIndex, without, find } from "lodash";
import { handleError } from "src/handle-error.helper.js";
import toasts from "toast-service!sofe";
import { CprEmptyState, CpLoader } from "canopy-styleguide!sofe";

import {
  getBoilerplateItems,
  postBoilerplateCategory,
  putBoilerplateCategory,
  deleteBoilerplateCategory,
} from "../left-pane-tools/boilerplate.resource.js";
import Category from "./category.component.js";
import EditCategory from "./edit-category.component.js";

import styles from "../editor/editor.styles.css";

export default class BoilerplateContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      categories: null,
      newCategoryDialog: false,
      editCategory: null,
    };

    this.closeNewCategoryDialog = () => this.setState({ newCategoryDialog: false });
    this.openNewCategoryDialog = () => this.setState({ newCategoryDialog: true });
    this.editCategory = (category) => this.setState({ editCategory: category });
    this.stopEditingCategory = () => this.setState({ editCategory: null });
    this.unloaded = false;
  }

  componentDidMount() {
    this.update();
  }

  update() {
    this.disposable = getBoilerplateItems().subscribe(
      ({ boilerplate_categories }) => this.setState({ categories: boilerplate_categories }),
      handleError
    );
  }

  componentWillUnmount() {
    this.disposable.unsubscribe();
    this.unloaded = true;
  }

  createCategory(category) {
    postBoilerplateCategory(category).subscribe(
      ({ boilerplate_categories }) =>
        !this.unloaded &&
        this.setState({
          categories: [
            ...this.state.categories,
            {
              ...boilerplate_categories,
              raw_body: "",
            },
          ],
        }),
      handleError
    );

    this.closeNewCategoryDialog();
  }

  saveCategory(category) {
    putBoilerplateCategory(category).subscribe(({ boilerplate_categories }) => {
      const categories = [...this.state.categories];
      const index = findIndex(categories, { id: boilerplate_categories.id });
      categories[index] = boilerplate_categories;

      if (!this.unloaded) {
        this.setState({
          categories,
        });
      }
    }, handleError);

    this.stopEditingCategory();
  }

  removeCategory(category) {
    let timeout = setTimeout(() => {
      deleteBoilerplateCategory(category).subscribe(() => {}, handleError);
    }, 3000);

    this.setState({
      categories: without(this.state.categories, find(this.state.categories, { id: category.id })),
    });

    toasts.successToast(`Boilerplate ${category.title} deleted`, "Undo", () => {
      clearTimeout(timeout);

      if (!this.unloaded) {
        this.setState({
          categories: [...this.state.categories, category],
        });
      }
      toasts.successToast("Boilerplate restored successfully");
    });

    this.stopEditingCategory();
  }

  render() {
    const { categories, newCategoryDialog, editCategoryDialog, editCategory } = this.state;

    return (
      <div className="cps-card">
        {newCategoryDialog && (
          <EditCategory save={this.createCategory.bind(this)} close={this.closeNewCategoryDialog} />
        )}
        <div className="cps-card__header cps-subheader">Boilerplate Letter Text Templates</div>
        <div className="cps-card__body">
          <div className="cps-body cps-light-gray cps-padding-top-8 cps-padding-bottom-12">Boilerplate Text</div>
          <div className="cps-body-sm cps-padding-bottom-16">
            What's Boilerplate Text you ask? Boilerplate Text is a phrase or clause that you commonly use in your
            letters. You can think of it like small components of your practice's boilerplate language. When you're
            drafting a new letter or template, you can easily add any of these clauses to create your letter more
            quickly. Enjoy!
          </div>

          {!categories && (
            <div className={`${styles.loaderWrapper}`}>
              <CpLoader />
            </div>
          )}

          {categories && !categories.length ? (
            <div>
              <div className="cps-body-sm cps-padding-bottom-16">
                Get started by adding a category for some of your Boilerplate Text options.{" "}
                <span className="cps-wt-bold cps-color-primary">E.g.,</span>{" "}
                <span className="cps-light-gray">
                  You could categorize by services like: Tax Resolution, Tax Preparation, Bookkeeping, etc.
                </span>
              </div>
              <CprEmptyState
                iconSrc="es_boilerplate_text"
                iconSize="large"
                headText="Add a boilerplate category"
                subheadText="You can create a category to help you manage your boilerplate text"
                showCta={true}
                ctaClickHandler={this.openNewCategoryDialog}
                ctaButtonText="Add a category"
              />
            </div>
          ) : null}

          {categories && categories.length ? (
            <div>
              {sortBy(categories, "title").map((category, index) => (
                <Category
                  updateCategories={this.update.bind(this)}
                  editCategory={this.editCategory.bind(this, category)}
                  saveCategory={this.saveCategory.bind(this)}
                  stopEditingCategory={this.stopEditingCategory}
                  removeCategory={this.removeCategory.bind(this, category)}
                  key={index}
                  editing={editCategory && editCategory.id === category.id}
                  category={category}
                />
              ))}
              <button onClick={this.openNewCategoryDialog} className="cps-btn +primary cps-margin-bottom-8">
                Add a category
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
