import React from "react";

import styles from "./client-request-header.styles.css";

export default function ClientRequestHeader({ actions, title }) {
  return (
    <div>
      <input
        value={title}
        onChange={actions.changeRequestTitle}
        className={`${styles.clientRequestHeaderTitle}`}
        placeholder="Untitled"
      ></input>
    </div>
  );
}
