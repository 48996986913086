import * as types from "./editor.types.js";
import canopyUrls from "canopy-urls!sofe";
import { has, includes, isEqual } from "lodash";

import { convertToRaw } from "draft-js";
import toasts from "toast-service!sofe";
import {
  getLetter,
  persistLetter,
  removeLetter,
  getTemplate,
  persistTemplate,
  removeTemplate,
  getCanopyTemplate,
  postCanopyTemplate,
  persistCanopyTemplate,
  removeCanopyTemplate,
} from "./editor.resource.js";
import { getBoilerplateItems } from "../left-pane-tools/boilerplate.resource.js";
import { getMergeFieldItems, getClientMergeFieldValues } from "../left-pane-tools/merge-field.resource.js";
import { generateHtml } from "./draft.helper.js";
import { handleError } from "src/handle-error.helper.js";
import { PUBLISHED } from "./canopy-template/publish.constants";
export * from "./draft.actions.js";

export function loadLetter({ clientId, resolutionCaseId, letterId, letterEditorState }) {
  return (dispatch, getState) => {
    dispatch({
      type: types.DOCUMENT_LOADING,
    });

    getClientMergeFieldValues(clientId).subscribe((mergeFieldValues) => {
      dispatch({
        type: types.MERGE_FIELD_VALUES_LOADED,
        mergeFieldValues: mergeFieldValues,
      });

      getLetter(clientId, resolutionCaseId, letterId).subscribe((letters) =>
        dispatch({
          type: types.DOCUMENT_LOADED,
          documentType: "letter",
          document: letters,
          clientRequestId: has(letters, "relationships.last_client_request.id")
            ? letters.relationships.last_client_request.id
            : null,
          rawBody: letters.raw_body,
          mergeFieldValues,
        })
      );
    });
  };
}

export function saveLetter(clientId, resolutionCaseId, redirect = false) {
  return (dispatch, getState) => {
    const successFn = () => {
      dispatch({
        type: types.DOCUMENT_SAVED,
        documentType: "letter",
        clientId,
        resolutionCaseId,
      });

      if (redirect) {
        redirectToOrigin("letter", clientId, resolutionCaseId);
      }
    };

    const state = getState();
    if (!state.letterEditor || !state.letterEditor.document) {
      //Cannot save - Happens when navigating away from the page
      return;
    } else if (state.letterEditor.isSaved) {
      //Already saved
      successFn();
    } else {
      //Save letter to backend
      const content = state.letterEditor.editorState.getCurrentContent();
      const raw = JSON.stringify(convertToRaw(content));

      const html = generateHtml(content);

      persistLetter(clientId, resolutionCaseId, {
        ...state.letterEditor.document,
        body: html,
        raw_body: raw,
      }).subscribe(successFn);
    }
  };
}

export function deleteLetter(clientId, resolutionCaseId, letterId) {
  return (dispatch) => {
    removeLetter(clientId, resolutionCaseId, letterId).subscribe((response) => {
      dispatch({
        type: types.DOCUMENT_DELETED,
        documentType: "letter",
        clientId,
        resolutionCaseId,
      });

      redirectToOrigin("letter", clientId, resolutionCaseId);
    });
  };
}

//Reminder, cannot print or send a template - due to merge fields
export function print(clientId, resolutionCaseId, letterId) {
  return (dispatch, getState) => {
    saveLetter(
      clientId,
      resolutionCaseId,
      false
    )((saveAction) => {
      dispatch(saveAction);

      if (window && window.open) {
        //In Tests window is undefined
        const printWindow = window.open(
          `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/letters/${letterId}/pdf`,
          "_blank",
          "width=800,height=1000"
        );

        printWindow
          ? null
          : toasts.infoToast(
              "The print window didn't open! If you are using a pop-up blocking utility then it may have blocked it!",
              null,
              null,
              6000
            );
      }
      dispatch({
        type: types.PRINT,
      });
    }, getState);
  };
}

export function loadTemplate(templateId) {
  return (dispatch) => {
    dispatch({
      type: types.DOCUMENT_LOADING,
    });

    getTemplate(templateId).subscribe((template) =>
      dispatch({
        type: types.DOCUMENT_LOADED,
        documentType: "template",
        document: template,
        rawBody: template.raw_body,
      })
    );
  };
}

export function loadCanopyTemplate(templateId) {
  return (dispatch) => {
    dispatch({
      type: types.DOCUMENT_LOADING,
    });

    getCanopyTemplate(templateId).subscribe((template) =>
      dispatch({
        type: types.DOCUMENT_LOADED,
        documentType: "canopy_template",
        document: template,
        rawBody: template.raw_body,
      })
    );
  };
}

export function convertTemplateToCanopyTemplate() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: types.DOCUMENT_LOADING,
      documentType: "canopy_template",
    });
    const { document } = state.letterEditor;
    const template = (({ title, body, raw_body }) => ({ title, body, raw_body }))(document);
    postCanopyTemplate(template).subscribe(({ id }) => {
      window.location.href = `/#/letters/edit/canopy-template/${id}`;
    }, handleError);
  };
}

export function saveTemplate(redirect = false) {
  return (dispatch, getState) => {
    const successFn = () => {
      dispatch({
        type: types.DOCUMENT_SAVED,
        documentType: "template",
      });

      if (redirect) {
        redirectToOrigin("template");
      }
    };
    const state = getState();
    if (!state.letterEditor || !state.letterEditor.document) {
      //Cannot save - Happens when navigating away from the page
      return;
    } else if (state.letterEditor.isSaved) {
      //Template Already saved
      successFn();
    } else {
      //Save template to backend
      const content = state.letterEditor.editorState.getCurrentContent();
      const raw = JSON.stringify(convertToRaw(content));

      const html = generateHtml(content);

      persistTemplate({
        ...state.letterEditor.document,
        body: html,
        raw_body: raw,
      }).subscribe(successFn);
    }
  };
}

export function saveCanopyTemplate(redirect = false) {
  return (dispatch, getState) => {
    const { letterEditor } = getState();
    if (!letterEditor || !letterEditor.document) {
      //Cannot save - Happens when navigating away from the page
      return;
    } else {
      const content = letterEditor.editorState.getCurrentContent();
      const body = generateHtml(content);
      const { description, service_slugs } = letterEditor.document;
      const hasChanges =
        body !== letterEditor.document.body ||
        description !== letterEditor.document.description ||
        !isEqual(service_slugs, letterEditor.document.service_slugs);

      if (hasChanges) {
        const template = {
          ...letterEditor.document,
          body,
          raw_body: JSON.stringify(convertToRaw(content)),
          description,
          service_slugs,
        };

        if (letterEditor.document.status === PUBLISHED) {
          //Create copy if Canopy Template is already published
          postCanopyTemplate({ ...template, copied_from_template_id: template.id }).subscribe((newTemplate) => {
            dispatch({
              type: types.DOCUMENT_LOADED,
              documentType: "canopy_template",
              document: newTemplate,
              rawBody: newTemplate.raw_body,
            });
            if (includes(window.location, "edit/canopy_template")) {
              canopyUrls.navigateToUrl(`/#/letters/edit/canopy-template/${newTemplate.id}`);
            }
          });
        } else {
          //Save template to backend
          persistCanopyTemplate(template).subscribe(() => {
            dispatch({
              type: types.DOCUMENT_SAVED,
              documentType: "canopy_template",
            });
          });
        }
      }
      if (redirect) {
        redirectToOrigin("template");
      }
    }
  };
}

export function deleteTemplate(templateId) {
  return (dispatch, getState) => {
    removeTemplate(templateId).subscribe((response) => {
      dispatch({
        type: types.DOCUMENT_DELETED,
        documentType: "template",
      });

      redirectToOrigin("template");
    });
  };
}

export function deleteCanopyTemplate(templateId) {
  return (dispatch, getState) => {
    removeCanopyTemplate(templateId).subscribe((response) => {
      dispatch({
        type: types.DOCUMENT_DELETED,
        documentType: "canopy_template",
      });

      redirectToOrigin("template");
    });
  };
}

export function changeUnsavedTitleOfDocument(documentTitle) {
  return {
    type: types.UNSAVED_TITLE_OF_DOCUMENT_CHANGED,
    title: documentTitle,
  };
}

export function changeDocumentTitle(document, documentTitle) {
  return {
    type: types.DOCUMENT_TITLE_CHANGED,
    document: { ...document, title: documentTitle },
  };
}

export function toggleFontSizeMenuVisibility(e) {
  const el = e.target;
  const parent = el.parentElement || {};

  return {
    type: el.id === "fontSizeMenu" || parent.id === "fontSizeMenu" ? types.FONT_MENU_OPENED : types.FONT_MENU_CLOSED,
  };
}

export function toggleFontColorMenuVisibility(e) {
  const el = e.target;
  const parent = el.parentElement || {};
  let parentsParent;
  if (parent !== {}) {
    parentsParent = parent.parentElement || {};
  }

  const correctIDsFound =
    el.id === "fontColorMenu" || parent.id === "fontColorMenu" || parentsParent.id === "fontColorMenu";

  return {
    type: correctIDsFound ? types.COLOR_MENU_OPENED : types.COLOR_MENU_CLOSED,
  };
}

// Flash menu interactions
export function toggleFlashMenuVisibility(e) {
  const clickedFlashIcon = e.target.id === "flashIcon";

  if (clickedFlashIcon) e.preventDefault();

  return {
    type: clickedFlashIcon ? types.FLASH_ICON_CLICKED : types.FLASH_MENU_CLOSED,
  };
}

export function setLeftToolMenuType(selectedTool) {
  return {
    type: types.FLASH_MENU_ITEM_SELECTED,
    selectedTool: selectedTool,
  };
}

export function displaySigningModal(params) {
  const { clientId, resolutionCaseId, disabled } = params;
  return (dispatch, getState) => {
    const state = getState();
    if (!state.letterEditor.signingModalDisplayed && clientId && resolutionCaseId && !disabled) {
      dispatch({
        type: types.SIGNING_MODAL_TOGGLED,
        displayed: true,
      });
      saveLetter(clientId, resolutionCaseId)((saveAction) => {
        dispatch(saveAction);
        launchSigningModal(state, params, dispatch);
      }, getState);
    }
  };
}

function setSigningModalDisplayed(singingModalDisplayed, dispatch) {
  dispatch({
    type: types.SIGNING_MODAL_TOGGLED,
    displayed: singingModalDisplayed,
  });
}

function launchSigningModal(state, params, dispatch) {
  const { letterEditor, context } = state;
  const { clientId, resolutionCaseId } = params;

  window.SystemJS.import("signing-ui!sofe")
    .then((m) => {
      m.LetterSigningParcel.showSigningModal({
        clientId,
        documentType: "letter",
        documentId: letterEditor.document.id,
        context,
        resolutionCaseId,
        notificationIds: letterEditor.notificationIds,
        title: letterEditor.document.title,
        modalClosed: setSigningModalDisplayed.bind(this, false, dispatch),
      });
    })
    .catch((err) =>
      setTimeout(() => {
        throw err;
      })
    );
}

export function setNotificationIds(notificationIds) {
  return {
    type: types.SET_NOTIFICATION_IDS,
    notificationIds: notificationIds,
  };
}

// Share with client interactions
export function toggleShareMenuVisibility(e) {
  return {
    type: e.target.id === "shareLetterButton" ? types.SHARE_WITH_CLIENT_TOGGLED : types.SHARE_MENU_CLOSED,
  };
}

export function toggleClientRequestDialog() {
  return {
    type: types.CLIENT_REQUEST_DIALOG_TOGGLED,
  };
}

export function closeClientRequestDialog() {
  return {
    type: types.CLIENT_REQUEST_DIALOG_CLOSED,
  };
}

// Left tool menu interactions
export function closeLeftToolMenu() {
  return {
    type: types.LEFT_TOOL_MENU_CLOSED,
  };
}

export function loadBoilerplateText() {
  return (dispatch) => {
    getBoilerplateItems().subscribe((response) =>
      dispatch({
        type: types.BOILERPLATE_TEXT_LOADED,
        boilerplateText: response.boilerplate_categories,
      })
    );
  };
}

export function loadMergeFields() {
  return (dispatch, getState) => {
    getMergeFieldItems().subscribe((response) =>
      dispatch({
        type: types.MERGE_FIELD_LOADED,
        mergeFields: response.merge_fields,
      })
    );
  };
}

export function loadMergeFieldValues(clientId) {
  return (dispatch, getState) => {
    getClientMergeFieldValues(clientId).subscribe((response) =>
      dispatch({
        type: types.MERGE_FIELD_VALUES_LOADED,
        mergeFieldValues: response,
      })
    );
  };
}

export function unloadApp() {
  return {
    type: types.APP_UNLOADED,
  };
}

function redirectToOrigin(documentType, clientId, resolutionCaseId) {
  let urlBackToOrigin = "";

  if (documentType === "template") {
    urlBackToOrigin = "/#/letters/global-settings/templates";
  } else if (documentType === "letter") {
    urlBackToOrigin = `/#/taxes/client/${clientId}/resolution-cases/${resolutionCaseId}/letter-generator`;
  }

  canopyUrls.navigateToUrl(urlBackToOrigin);
}
