import React from "react";

import SaveAndExit from "./save-and-exit.component.js";
import TextStylingToolbar from "./text-styling-toolbar.component.js";
import ShareLetter from "./share-letter.component.js";

import styles from "./editor-toolbar.styles.css";

export default function EditorToolbar(props) {
  return (
    <div className={`${styles.editorToolbarContainer}`}>
      <div className={`cps-margin-left-24 ${styles.editorToolbarElement}`}>
        <SaveAndExit {...props} />
      </div>
      <div className={`${styles.editorToolbarElement}`}>
        <TextStylingToolbar
          actions={props.actions}
          editorState={props.editorState}
          editorInstance={props.editorInstance}
          fontMenuOpen={props.letterEditorState.fontMenuOpen}
          colorMenuOpen={props.letterEditorState.colorMenuOpen}
          previewDisplayed={props.previewDisplayed}
          documentType={props.letterEditorState.type}
          params={props.params}
          isLetterEditor
        />
      </div>
      <div
        className={`cps-margin-right-24 cps-padding-0 ${styles.editorToolbarElement}`}
        style={{ justifyContent: "flex-end", flexBasis: "fill", whiteSpace: "normal" }}
      >
        {props.letterEditorState.type === "letter" ? (
          <ShareLetter
            loggedInUser={props.loggedInUser}
            actions={props.actions}
            shareWithClientMenuDisplayed={props.letterEditorState.shareWithClientMenuDisplayed}
            clientId={props.params.clientId}
            resolutionCaseId={props.params.resolutionCaseId}
            letterId={props.params.letterId}
          />
        ) : null}
      </div>
    </div>
  );
}
