import React from "react";
import Downshift from "downshift";
import { find, isEqual } from "lodash";

/* Adapted from https://codesandbox.io/s/W6gyJ30kn */
class MultiDownshift extends React.Component {
  state = {
    selectedItems: this.props.defaultSelectedItem || [],
  };

  stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          isOpen: true,
        };
      default:
        return changes;
    }
  };

  handleSelection = (selectedItem) => {
    if (!find(this.state.selectedItems, selectedItem)) {
      this.addSelectedItem(selectedItem);
    } else {
      this.removeItem(selectedItem);
    }
  };

  callOnChange = () => {
    if (this.props.onSelect) {
      this.props.onSelect(this.state.selectedItems, this.getStateAndHelpers(this.downshift));
    }
    if (this.props.onChange) {
      this.props.onChange(this.state.selectedItems, this.getStateAndHelpers(this.downshift));
    }
  };

  removeItem(item) {
    this.setState(
      ({ selectedItems }) => ({
        selectedItems: selectedItems.filter((i) => !isEqual(i, item)),
      }),
      this.callOnChange
    );
  }

  addSelectedItem(item) {
    this.setState(
      ({ selectedItems }) => ({
        selectedItems: [...selectedItems, item],
      }),
      this.callOnChange
    );
  }

  getRemoveButtonProps = ({ onClick, item, ...props } = {}) => {
    return {
      onClick: (e) => {
        // TODO: use something like downshift's composeEventHandlers utility instead
        onClick && onClick(e);
        e.stopPropagation();
        this.removeItem(item);
      },
      ...props,
    };
  };

  getStateAndHelpers(downshift) {
    this.downshift = downshift;
    const { selectedItems } = this.state;
    const { getRemoveButtonProps } = this;
    return {
      getRemoveButtonProps,
      selectedItems,
      ...downshift,
    };
  }
  render() {
    const { render, children = render, ...props } = this.props;
    // TODO: compose together props (rather than overwriting them) like downshift does
    return (
      <Downshift {...props} stateReducer={this.stateReducer} onChange={this.handleSelection} selectedItem={null}>
        {(downshift) => children(this.getStateAndHelpers(downshift))}
      </Downshift>
    );
  }
}

export default MultiDownshift;
