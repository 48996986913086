import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { partial } from "lodash";

import * as editorActions from "./editor.actions.js";
import * as previewActions from "../preview/preview.actions.js";

import EditorComponent from "./editor.component.js";

@connect((state) => ({
  letterEditorState: state.letterEditor,
  preview: state.preview,
}))
export default class LetterEditorContainer extends React.Component {
  constructor(props) {
    super();

    const { clientId, letterId, resolutionCaseId } = props.match.params;
    this.actions = {
      ...bindActionCreators(editorActions, props.dispatch),
      ...bindActionCreators(previewActions, props.dispatch),
      deleteDocument: bindActionCreators(
        partial(editorActions.deleteLetter, clientId, resolutionCaseId, letterId),
        props.dispatch
      ),
      saveDocument: bindActionCreators(partial(editorActions.saveLetter, clientId, resolutionCaseId), props.dispatch),
    };
  }

  render() {
    return <EditorComponent {...this.props} context={this.props.context} actions={this.actions} />;
  }

  componentDidMount() {
    const actions = this.actions;

    actions.loadBoilerplateText();
    actions.loadMergeFields();

    actions.loadLetter({ ...this.props.match.params });
  }
}
