// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* client-request-due-date.style.css */\n\n.src-client-request-client-request-due-date-styles__dueDate {\n  display: flex;\n  align-items: center;\n  border-bottom: .1rem solid var(--cps-color-silver);\n  padding: 2.4rem;\n  height: 4.5rem;\n  background-color: var(--cps-color-bumble);\n}\n\n.src-client-request-client-request-due-date-styles__dueDateLabel {\n  font-size: 1.4rem;\n  font-weight: bold;\n  padding-right: .5rem;\n}\n\n.src-client-request-client-request-due-date-styles__dueDatePicker {\n  opacity: 0;\n  position: absolute;\n}\n\n.src-client-request-client-request-due-date-styles__removeButton {\n  margin-left: auto;\n}", ""]);
// Exports
exports.locals = {
	"dueDate": "src-client-request-client-request-due-date-styles__dueDate",
	"dueDateLabel": "src-client-request-client-request-due-date-styles__dueDateLabel",
	"dueDatePicker": "src-client-request-client-request-due-date-styles__dueDatePicker",
	"removeButton": "src-client-request-client-request-due-date-styles__removeButton"
};
module.exports = exports;
