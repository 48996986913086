import * as types from "./client-request.types.js";
import * as editorTypes from "../editor/editor.types.js";
import { EditorState, convertFromHTML, ContentState } from "draft-js";

const DEFAULT_STATE = {
  visible: false,
  clientRequestBody: null,
  requestTitle: null,
  dueDate: null,
  reminderModalDisplayed: false,
  savedReminders: { days: "", period: "" },
  reminderDays: undefined,
  reminderPeriod: null,
  requestEditorState: EditorState.createEmpty(),
  filesize: "",
  requestSent: false,
  clientCollaborators: [],
};

export default function (state = DEFAULT_STATE, action) {
  if (action.type === types.ANIMATE_SHOW_TOGGLED) {
    return { ...state, visible: action.visible };
  }

  if (action.type === types.CLIENT_REQUEST_CREATED) {
    return { ...state, clientRequestBody: action.clientRequestBody, requestSent: action.requestSent };
  }

  if (action.type === types.CLIENT_REQUEST_LOADED) {
    const newEditorState = EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(action.description || ""))
    );

    return {
      ...state,
      clientRequestBody: action.clientRequestBody,
      requestEditorState: newEditorState,
      requestSent: action.requestSent,
      requestTitle: action.title,
      dueDate: action.dueDate,
      savedReminders: { days: action.reminderDays, period: action.period },
    };
  }

  if (action.type === types.EMPTY_CLIENT_REQUEST_LOADED) {
    return DEFAULT_STATE;
  }

  if (action.type === types.CLIENT_REQUEST_ATTACHMENT_LOADED) {
    return { ...state, filesize: action.filesize };
  }

  if (action.type === types.REQUEST_TITLE_CHANGED) {
    return { ...state, requestTitle: action.title };
  }

  if (action.type === types.REMINDER_MODAL_TOGGLED) {
    const reminderDays = state.savedReminders.days || state.reminderDays;
    const reminderPeriod = state.savedReminders.period || state.reminderPeriod;
    return {
      ...state,
      reminderModalDisplayed: action.reminderModalDisplayed,
      reminderDays: reminderDays,
      reminderPeriod: reminderPeriod,
    };
  }

  if (action.type === types.REMINDER_DAYS_SET) {
    return { ...state, reminderDays: action.reminderDays };
  }

  if (action.type === types.REMINDER_PERIOD_SET) {
    return { ...state, reminderPeriod: action.reminderPeriod };
  }

  if (action.type === types.REMINDER_SAVED) {
    return {
      ...state,
      reminderModalDisplayed: false,
      savedReminders: { days: state.reminderDays, period: state.reminderPeriod },
    };
  }

  if (action.type === types.REMINDER_REMOVED) {
    return { ...state, reminderModalDisplayed: false, savedReminders: { days: "", period: "" } };
  }

  if (action.type === types.DUE_DATE_SET) {
    return { ...state, dueDate: action.dueDate };
  }

  if (action.type === types.DUE_DATE_REMOVED) {
    return { ...state, dueDate: null };
  }

  if (action.type === types.UPDATED_REQUEST_EDITOR_STATE) {
    return { ...state, requestEditorState: action.editorState };
  }

  if (action.type === types.GOT_CLIENT_USERS) {
    return { ...state, clientCollaborators: action.load };
  }

  if (action.type === editorTypes.APP_UNLOADED) {
    return DEFAULT_STATE;
  }

  return state;
}
