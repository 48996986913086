import React from "react";
import { NavContent } from "primary-navbar!sofe";

export default class App extends React.Component {
  render() {
    const props = this.props;

    const shouldBeFullWidth = props.location.pathname.indexOf("global-settings") > -1 ? false : true;

    return (
      <div>
        <NavContent
          hasTopnavSecondary={true}
          topNavSecondaryHeight={shouldBeFullWidth ? 96 : 0}
          clientMenuPossible={false}
        >
          <div className="cps-flexible-focus">{props.children}</div>
        </NavContent>
      </div>
    );
  }
}
