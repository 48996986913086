// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cps-btn-icon .src-secondary-nav-text-styling-styles__gray {\n  color: #bbbbbb!important;\n}\n", ""]);
// Exports
exports.locals = {
	"gray": "src-secondary-nav-text-styling-styles__gray"
};
module.exports = exports;
