import React from "react";

import styles from "./client-request-description.styles.css";
import TextStylingToolbar from "./client-request-text-styling-toolbar.component.js";
import ClientRequestFile from "./client-request-file.component.js";

import { Editor } from "draft-js";

export default class ClientRequestDescription extends React.Component {
  constructor(props) {
    super(props);

    this.focus = () => this.requestEditorInstance.focus();
  }

  render() {
    const props = this.props;
    const actions = props.actions;
    const clientRequest = props.clientRequest;

    return (
      <div>
        <div className={`${styles.description}`}>
          <div className={`${styles.descriptionToolbar}`}>
            <TextStylingToolbar
              actions={actions}
              editorState={clientRequest.requestEditorState}
              editorInstance={this.requestEditorInstance}
            />
          </div>
          <div className={`${styles.descriptionTextBox}`} onClick={this.focus}>
            <Editor
              editorState={clientRequest.requestEditorState}
              onChange={actions.updateState}
              handleKeyCommand={handleKeyCommand.bind(null, props.actions, clientRequest.requestEditorState)}
              placeholder={`Add a description`}
              spellCheck={true}
              ref={(editor) => {
                this.requestEditorInstance = editor;
              }}
            />
          </div>
        </div>
        <div className={`${styles.clientRequestAttachmentPadding}`}>
          <ClientRequestFile
            downloadParams={props.params}
            filename={props.letterTitle}
            filesize={clientRequest.filesize}
          />
        </div>
      </div>
    );
  }
}

function handleKeyCommand(actions, existingState, command) {
  actions.handleKeyCommand(existingState, command);
}
