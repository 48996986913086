// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* side-pane.style.css */\n\n.src-client-request-client-request-styles__sidePane {\n  width: 34.4rem;\n  position: fixed;\n  transition: .5s right;\n  height: calc(100% - 17.4rem);\n}\n\n.src-client-request-client-request-styles__sidePaneContainer {\n  margin-left: 2.4rem;\n  position: relative;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  z-index: 51; /* on top of __hide */\n  background-color: #fafafa;\n\n}\n\n.src-client-request-client-request-styles__sidePaneHeader {\n  padding: 2.4rem;\n  margin-top: .25rem; /* to even out the margin top on the side pane that goes underneath the menu */\n  flex-shrink: 0;\n  border-bottom: .1rem solid #e9e9e9;\n  background: white;\n  margin-right: -16px;\n}\n\n.src-client-request-client-request-styles__sidePaneFooter {\n  background-color: #fafafa;\n  width: 100%;\n}\n\n.src-client-request-client-request-styles__sendActions {\n  padding: 2.4rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  border-top: .1rem solid #e9e9e9;\n}\n", ""]);
// Exports
exports.locals = {
	"sidePane": "src-client-request-client-request-styles__sidePane",
	"sidePaneContainer": "src-client-request-client-request-styles__sidePaneContainer",
	"sidePaneHeader": "src-client-request-client-request-styles__sidePaneHeader",
	"sidePaneFooter": "src-client-request-client-request-styles__sidePaneFooter",
	"sendActions": "src-client-request-client-request-styles__sendActions"
};
module.exports = exports;
