import {
  submitCanopyTemplateForReview,
  rejectCanopyTemplate,
  submitCanopyTemplateForPublish,
  unpublishCanopyTemplate,
  undoSubmitCanopyTemplateForReview,
} from "./publish.resource";
import * as types from "./publish.types";
import { DOCUMENT_LOADED } from "../editor.types";
import { successToast, infoToast } from "toast-service!sofe";
import { convertToRaw } from "draft-js";
import { generateHtml } from "../draft.helper.js";

export function updateCanopyTemplateDescription(description) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CANOPY_TEMPLATE_DESCRIPTION,
      description,
    });
  };
}

export function updateCanopyTemplateServiceSlugs(service_slugs) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CANOPY_TEMPLATE_SERVICE_SLUGS,
      service_slugs,
    });
  };
}

const templateSubmittedToast = (templateName, hasPrevChanges) => {
  return hasPrevChanges
    ? `${templateName} was submitted for review.`
    : `${templateName} changes were submitted for review.`;
};

const getTemplateCommonAction = (dispatch, getState) => {
  dispatch({
    type: types.SUBMITTING,
  });
  const letterEditor = getState().letterEditor;
  const { document, publishState } = letterEditor;
  const content = letterEditor.editorState.getCurrentContent();
  const raw_body = JSON.stringify(convertToRaw(content));
  const body = generateHtml(content);
  const template = {
    ...document,
    ...publishState,
    body,
    raw_body,
  };
  return template;
};

export function submitForReview() {
  return (dispatch, getState) => {
    const template = getTemplateCommonAction(dispatch, getState);
    submitCanopyTemplateForReview(template).subscribe((template) => {
      dispatch({
        type: DOCUMENT_LOADED,
        documentType: "canopy_template",
        document: template,
        rawBody: template.raw_body,
      });
      successToast(templateSubmittedToast(template.title, document.status !== template.status), "Undo", () => {
        undoSubmitCanopyTemplateForReview(template).subscribe((undoneTemplate) => {
          dispatch({
            type: DOCUMENT_LOADED,
            documentType: "canopy_template",
            document: undoneTemplate,
            rawBody: undoneTemplate.raw_body,
          });
        });
      });
    });
  };
}

export function reject() {
  return (dispatch, getState) => {
    dispatch({
      type: types.SUBMITTING,
    });
    const template = getTemplateCommonAction(dispatch, getState);
    rejectCanopyTemplate(template).subscribe((template) => {
      dispatch({
        type: DOCUMENT_LOADED,
        documentType: "canopy_template",
        document: template,
        rawBody: template.raw_body,
      });
      infoToast(`${template.title} was rejected.`);
    });
  };
}

export function publish() {
  return (dispatch, getState) => {
    dispatch({
      type: types.SUBMITTING,
    });
    const { id } = getState().letterEditor.document;
    submitCanopyTemplateForPublish(id).subscribe((template) => {
      dispatch({
        type: DOCUMENT_LOADED,
        documentType: "canopy_template",
        document: template,
        rawBody: template.raw_body,
      });
      successToast(`${template.title} was published.`);
    });
  };
}

export function unpublish() {
  return (dispatch, getState) => {
    dispatch({
      type: types.SUBMITTING,
    });
    const { id } = getState().letterEditor.document;
    unpublishCanopyTemplate(id).subscribe((template) => {
      dispatch({
        type: DOCUMENT_LOADED,
        documentType: "canopy_template",
        document: template,
        rawBody: template.raw_body,
      });
      infoToast(`${template.title} was unpublished.`);
    });
  };
}
