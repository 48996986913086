import canopyUrls from "canopy-urls!sofe";
import { fetchAsObservable } from "fetcher!sofe";

export function getMergeFieldItems() {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/merge_fields`);
}

export function getClientMergeFieldValues(clientId) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/merge_fields`);
}
