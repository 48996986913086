export const PUBLISHED = "Published";
export const SUBMITTED = "Submitted for review";
export const PUBLISHED_WITH_SUBMITTED_CHANGES = "Published with submitted changes";
export const UNPUBLISHED_WITH_SUBMITTED_CHANGES = "Unpublished with submitted changes";
export const UNSUBMITTED_CHANGES = "Unsubmitted changes";
export const PUBLISHED_WITH_UNSUBMITTED_CHANGES = "Published with unsubmitted changes";
export const UNPUBLISHED_WITH_UNSUBMITTED_CHANGES = "Unpublished with unsubmitted changes";
export const REJECTED = "Rejected";
export const PUBLISHED_WITH_REJECTED_CHANGES = "Published with rejected changes";
export const UNPUBLISHED_WITH_REJECTED_CHANGES = "Unpublished with rejected changes";
export const UNSUBMITTED = "Unsubmitted";
export const UNPUBLISHED = "Unpublished";
