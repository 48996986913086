// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-editor-canopy-template-fake-checkbox-styles__fake-checkbox {\n  border-radius: 5px;\n  border: 2px solid #AFAFAF;\n  display: inline-block;\n  width: 16px;\n  height: 16px;\n  background-color: transparent;\n  transition: .15s ease-in background-color;\n  margin-right: 8px;\n  position: relative;\n}\n.src-editor-canopy-template-fake-checkbox-styles__fake-checkbox::after {\n  content: \"\";\n  display: inline-block;\n  font-family: 'canopy-icons';\n  speak: none;\n  font-style: normal;\n  font-weight: normal;\n  font-variant: normal;\n  text-transform: none;\n  line-height: 1;\n  color: #fff;\n  transform: scale(1.8) translateY(-15%);\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n.src-editor-canopy-template-fake-checkbox-styles__checked {\n  background-color: #00BF4D;\n  border-color: #00BF4D;\n}\n.src-editor-canopy-template-fake-checkbox-styles__checked::after {\n  content: \"\\f18f\";\n}", ""]);
// Exports
exports.locals = {
	"fake-checkbox": "src-editor-canopy-template-fake-checkbox-styles__fake-checkbox",
	"checked": "src-editor-canopy-template-fake-checkbox-styles__checked"
};
module.exports = exports;
