import React from "react";

export default class EditCategory extends React.Component {
  constructor(props) {
    super();
    this.state = {
      title: props.category ? props.category.title : "",
    };
    this.updateTitle = (e) => this.setState({ title: e.target.value });
  }

  componentDidMount() {
    this.input.focus();
  }

  save() {
    const category = this.props.category || {};

    this.props.save({
      ...category,
      title: this.state.title,
    });
  }

  render() {
    const { close, category, remove } = this.props;
    const { title } = this.state;

    return (
      <div className="cps-modal">
        <div className="cps-modal__screen"></div>
        <div className="cps-modal__dialog cps-card" style={{ width: 600 }}>
          <div className="cps-card__header cps-subheader-sm">
            {!category && <span>Create a Category</span>}
            {category && <span>Edit Category</span>}
            <a className="cps-modal__dialog__close cps-icon cps-icon-close" onClick={close}></a>
          </div>
          <div className="cps-card__body">
            <form name="categoryForm" autoComplete="off" onSubmit={(e) => e.preventDefault() || (title && this.save())}>
              <div className="cps-form-group">
                <label>Name your category</label>
                <input
                  ref={(el) => {
                    this.input = el;
                  }}
                  onChange={this.updateTitle}
                  value={title}
                  name="title"
                  type="text"
                  className="cps-form-control"
                  maxLength="60"
                />
              </div>
            </form>
          </div>
          <div className="cps-padding-top-0 cps-padding-left-24 cps-padding-bottom-24" style={{ paddingRight: 13 }}>
            <button onClick={this.save.bind(this)} className="cps-btn +primary" disabled={!title}>
              Save category
            </button>
            <a className="cps-link" onClick={close}>
              Cancel
            </a>
            {category && (
              <a onClick={remove} className="cps-link cps-red cps-pull-right">
                Delete category
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}
