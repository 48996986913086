// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-secondary-nav-letter-name-styles__documentNameInput {\n  color: black;\n  font-style: italic;\n  font-weight: bold;\n  font-size: 16px;\n  padding-left: 4px;\n}", ""]);
// Exports
exports.locals = {
	"documentNameInput": "src-secondary-nav-letter-name-styles__documentNameInput"
};
module.exports = exports;
