import * as types from "./preview.types.js";
import { getLetterPreview, getTemplatePreview } from "../editor/editor.resource.js";
import { saveLetter, saveTemplate } from "../editor/editor.actions.js";

export function loadPreview({ clientId, resolutionCaseId, letterId, templateId }) {
  return (dispatch, getState) => {
    dispatch({ type: types.PREVIEW_LOADING });

    if (templateId) {
      saveTemplate(false)((saveAction) => {
        dispatch(saveAction);

        getTemplatePreview(templateId).subscribe(({ svgs }) => {
          dispatch({
            type: types.PREVIEW_LOADED,
            svgs: svgs.map(toCacheBustedUrls),
          });
        });
      }, getState);
    } else {
      saveLetter(
        clientId,
        resolutionCaseId,
        false
      )((saveAction) => {
        dispatch(saveAction);

        getLetterPreview(clientId, resolutionCaseId, letterId).subscribe(({ svgs }) => {
          dispatch({
            type: types.PREVIEW_LOADED,
            svgs: svgs.map(toCacheBustedUrls),
          });
        });
      }, getState);
    }

    function toCacheBustedUrls(svg) {
      return { ...svg, url: `${svg.url}?b=${new Date().getTime()}` };
    }
  };
}

export function closePreview() {
  return { type: types.PREVIEW_CLOSED };
}
