import React from "react";

import styles from "./client-request-reminders.styles.css";
import { CpSelectSingle } from "canopy-styleguide!sofe";

export default function ClientRequestReminders({ actions, reminderDays, reminderPeriod }) {
  const reminderDaysList = [
    { id: "weekday", name: "Every Weekday" },
    { id: "every-mwf", name: "Every Monday, Wednesday, Friday" },
    { id: "every-tth", name: "Every Tuesday, Thursday" },
    { id: "every-m", name: "Every Monday" },
  ];
  const reminderPeriodList = [
    { id: "15", name: "15 Days" },
    { id: "30", name: "30 Days" },
    { id: "45", name: "45 Days" },
    { id: "60", name: "60 Days" },
  ];
  let reminderDaysObj = reminderDaysList.filter((reminder) => reminder.id === reminderDays)[0];
  let reminderPeriodObj = reminderPeriodList.filter((period) => +period.id === +reminderPeriod)[0];

  return (
    <div className={`cps-card ${styles.remindersDropdown}`}>
      <div className={`cps-padding-bottom-16 ${styles.reminderDropdownTitle}`}>Client Reminder</div>
      <div className={`${styles.remindersDropdownOptions}`}>
        <div>Every</div>
        <div className={`${styles.remindersDropdownOptionsFrequency}`}>
          <CpSelectSingle
            contentWidth="block"
            data={reminderDaysList}
            onChange={actions.setReminderDays}
            placeholder="Select weekdays, Mondays..."
            triggerIsBlock
            value={reminderDaysObj}
          />
        </div>
        <div>For</div>
        <div className={`${styles.remindersDropdownOptionsExpiration}`}>
          <CpSelectSingle
            contentWidth="block"
            data={reminderPeriodList}
            onChange={actions.setReminderPeriod}
            placeholder="Select 15 days, 30 days, 45..."
            triggerIsBlock
            value={reminderPeriodObj}
          />
        </div>
      </div>
      <div className="cps-padding-bottom-24 cps-padding-top-8">or until request is completed.</div>
      <div>
        <button className="cps-btn +primary" onClick={actions.setReminder}>
          Save
        </button>
        <button className="cps-link" onClick={actions.toggleReminderMenu.bind(null, false)}>
          Cancel
        </button>
      </div>
    </div>
  );
}
