// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* client-request-file.style.css */\n\n.src-client-request-client-request-file-styles__file {\n  background-color: white;\n  cursor: default;\n  border: solid 1px #dfdfdf;\n  border-radius: 0.5rem;\n  box-sizing: border-box;\n  padding: 1.2rem 1.6rem;\n  height: 5.6rem;\n  overflow: hidden;\n  position: relative;\n  margin-bottom: 1.2rem;\n\n  max-width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.src-client-request-client-request-file-styles__fileLeft {\n  flex-shrink: 1;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.src-client-request-client-request-file-styles__fileIcon {\n  height: 3.2rem;\n  margin-right: 1.6rem;\n  flex-shrink: 0;\n}\n\n.src-client-request-client-request-file-styles__fileDetails {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  flex-shrink: 1;\n}\n\n.src-client-request-client-request-file-styles__fileName {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.src-client-request-client-request-file-styles__fileSize {\n  color: #afafaf;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.src-client-request-client-request-file-styles__fileActions {\n  flex-shrink: 0;\n}\n\n.src-client-request-client-request-file-styles__fileActionsIcons {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  flex-shrink: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"file": "src-client-request-client-request-file-styles__file",
	"fileLeft": "src-client-request-client-request-file-styles__fileLeft",
	"fileIcon": "src-client-request-client-request-file-styles__fileIcon",
	"fileDetails": "src-client-request-client-request-file-styles__fileDetails",
	"fileName": "src-client-request-client-request-file-styles__fileName",
	"fileSize": "src-client-request-client-request-file-styles__fileSize",
	"fileActions": "src-client-request-client-request-file-styles__fileActions",
	"fileActionsIcons": "src-client-request-client-request-file-styles__fileActionsIcons"
};
module.exports = exports;
