import * as types from "./context.types.js";

const defaultState = {
  loggedInUser: null,
  tenant: null,
};

export default function reducer(state = defaultState, action) {
  if (action.type === types.USER_LOGGED_IN) {
    return {
      ...state,
      loggedInUser: action.user,
      tenant: action.tenant,
    };
  }

  return state;
}
