import { pluck } from "rxjs/operators";

import { fetchAsObservable } from "fetcher!sofe";
import canopyUrls from "canopy-urls!sofe";
import { persistCanopyTemplate } from "../editor.resource";
import { SUBMITTED, REJECTED, UNSUBMITTED } from "./publish.constants";

export function submitCanopyTemplateForReview(template) {
  return persistCanopyTemplate({ ...template, status: SUBMITTED });
}

export function submitCanopyTemplateForPublish(templateId) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/letters/canopy-templates/${templateId}:publish`, {
    method: "PATCH",
  }).pipe(pluck("template"));
}

export function rejectCanopyTemplate(template) {
  return persistCanopyTemplate({ ...template, status: REJECTED });
}

export function unpublishCanopyTemplate(templateId) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/letters/canopy-templates/${templateId}:unpublish`, {
    method: "PATCH",
  }).pipe(pluck("template"));
}

export function undoSubmitCanopyTemplateForReview(template) {
  return persistCanopyTemplate({ ...template, status: UNSUBMITTED });
}
