import React from "react";
import styles from "./publish-status.styles.css";
import * as constant from "./publish.constants";

export const getStatusColor = (status) => {
  switch (status) {
    case constant.PUBLISHED:
      return "var(--cps-color-primary)";
    case constant.SUBMITTED:
    case constant.PUBLISHED_WITH_SUBMITTED_CHANGES:
    case constant.UNPUBLISHED_WITH_SUBMITTED_CHANGES:
      return "#3399FF";
    case constant.UNSUBMITTED_CHANGES:
    case constant.PUBLISHED_WITH_UNSUBMITTED_CHANGES:
    case constant.UNPUBLISHED_WITH_UNSUBMITTED_CHANGES:
      return "#F0D800";
    case constant.REJECTED:
    case constant.PUBLISHED_WITH_REJECTED_CHANGES:
    case constant.UNPUBLISHED_WITH_REJECTED_CHANGES:
      return "#FF345E";
    case constant.UNSUBMITTED:
    case constant.UNPUBLISHED:
    default:
      return "#919191";
  }
};

const PublishStatus = (props) => {
  let status = props.status;
  if (props.hasPreviousCopy) {
    switch (status) {
      case constant.UNSUBMITTED:
        status = constant.PUBLISHED_WITH_UNSUBMITTED_CHANGES;
        break;
      case constant.SUBMITTED:
        status = constant.PUBLISHED_WITH_SUBMITTED_CHANGES;
        break;
      case constant.REJECTED:
        status = constant.PUBLISHED_WITH_REJECTED_CHANGES;
        break;
    }
  }

  return (
    <div>
      <span
        className={`${styles.editorPublishStatus} cps-icon cps-icon-circle`}
        style={{ color: getStatusColor(status) }}
        aria-hidden="true"
      ></span>
      <span className="cps-caption">{status}</span>
    </div>
  );
};

export default PublishStatus;
