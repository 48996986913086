// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-admin-templates-styles__template {\n  padding: 1.6rem;\n  display: flex !important;\n  align-items: center;\n  justify-content: space-between;\n  background-color: initial !important;\n  border-bottom: 1px solid var(--cp-color-app-border);\n}\n\n.src-admin-templates-styles__template:last-of-type {\n  border-bottom: none;\n}\n", ""]);
// Exports
exports.locals = {
	"template": "src-admin-templates-styles__template"
};
module.exports = exports;
