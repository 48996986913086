import React from "react";
import { some, find, map } from "lodash";

import styles from "./text-styling-toolbar.styles.css";
import { click } from "../common/common.utils.js";

const FONT_COLORS = [
  { key: "BLACKclr", label: "Black", value: "#333" },
  { key: "GREYclr", label: "Grey", value: "#8B8B8B" },
  { key: "REDclr", label: "Red", value: "#E51515" },
  { key: "BLUEclr", label: "Blue", value: "#0D73D9" },
  { key: "GREENclr", label: "Green", value: "#05C127" },
  { key: "ORANGEclr", label: "Orange", value: "#FCB104" },
  { key: "PURPLEclr", label: "Purple", value: "#A742FF" },
];

export default function FontColorSelectDropdown({ disabled, actions, editorState, colorMenuOpen, editorInstance }) {
  const currentTextColor = getCurrentFontColor(editorState.getCurrentInlineStyle());

  const fontColorItems = map(FONT_COLORS, (fontColor) => {
    let activeFontColor;

    if (currentTextColor === undefined && fontColor.key === "BLACKclr") {
      activeFontColor = true;
    } else {
      activeFontColor = fontColor.key === currentTextColor;
    }

    return (
      <li key={fontColor.key}>
        <a
          style={{ padding: "0 0 0 11px" }}
          className={activeFontColor ? "cps-color-primary" : ""}
          onMouseDown={click.bind(
            null,
            actions.toggleInlineFontColor.bind(null, editorState, `${fontColor.key}`, editorInstance)
          )}
          data-testid="test-fontColor"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "2px",
                paddingTop: "4px",
                backgroundColor: `${fontColor.value}`,
              }}
            />
            <i
              className="cps-icon cps-color-primary cps-icon-sm-check"
              style={{ visibility: activeFontColor ? "visible" : "hidden" }}
            />
          </div>
        </a>
      </li>
    );
  });

  return (
    <span className={`cps-margin-right-8 cps-dropdown ${colorMenuOpen ? "cps-open" : ""}`}>
      <a
        id="fontColorMenu"
        data-testid={`${disabled ? "test-fontColorMenuDisabled" : "test-fontColorMenu"}`}
        disabled={disabled}
        style={{
          width: "34px",
          height: "34px",
          margin: "2px",
          borderRadius: "5px",
        }}
        className={`cps-link ${disabled ? styles.gray : ""}`}
        onMouseDown={click.bind(null, actions.toggleFontColorMenuVisibility.bind(null))}
      >
        <div
          style={{
            display: "flex",
            paddingTop: "6px",
          }}
        >
          <div
            style={{
              width: "15px",
              height: "15px",
              borderRadius: "2px",
              backgroundColor: `${findCurrentFontColorValue(currentTextColor)}`,
            }}
          />
          <span className="cps-caret" style={{ marginLeft: "3px", marginTop: "6px" }}></span>
        </div>
      </a>
      <ul className="cps-dropdown-menu" style={{ minWidth: "38px" }} role="menu">
        {fontColorItems}
      </ul>
    </span>
  );
}

const findCurrentFontColorValue = (currentTextColor) => {
  const foundColor = find(FONT_COLORS, ["key", currentTextColor]);

  return (foundColor && foundColor.value) || "#333";
};

function getCurrentFontColor(currentInlineStyles) {
  try {
    // currentInlineStyles is an ordered map, the beginning styles have lowest precedence,
    // hence the array is reversed so that find returns the highest precedence, there
    // should only really be one color applied at a time, as we don't allow multiple colors
    return currentInlineStyles
      .toArray()
      .reverse()
      .find((style) => {
        return style.match(/clr/) && some(FONT_COLORS, ["key", style]);
      });
  } catch (ex) {
    // return default font color, black, denoted by the 'undefined' returned here
    return undefined;
  }
}
