// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/** client-request-description.style.css **/\n\n/*****\nDescription Directive Wrapper\n*****/\n.src-client-request-client-request-description-styles__description {\n  background-color: white;\n  padding: 1.7rem 2.4rem 2rem;\n  word-wrap: break-word;\n}\n\n.src-client-request-client-request-description-styles__descriptionTextBox {\n  min-height: 100px;\n}\n\n.src-client-request-client-request-description-styles__descriptionTextBox .public-DraftEditorPlaceholder-root {\n  color: var(--cps-color-cool-gray);\n}\n\n.src-client-request-client-request-description-styles__descriptionTextBox .public-DraftEditorPlaceholder-inner {\n  position: absolute;\n}\n\n/*****\nText Toolbar /\n*****/\n.src-client-request-client-request-description-styles__descriptionToolbar {\n  display: inline-block;\n  position: relative;\n  left: -8px;\n  padding-bottom: 1rem;\n}\n\n.src-client-request-client-request-description-styles__descriptionToolbarBtn {\n  color: #afafaf;\n  padding: 4px;\n  padding-right: 0px !important;\n  font-size: 2.4rem;\n  background-color: rgba(0, 0, 0, 0);\n  border: 0;\n  font-family: 'canopy-icons';\n  speak: none;\n  font-style: normal;\n  font-weight: normal;\n  font-variant: normal;\n  text-transform: none;\n  line-height: 1;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.src-client-request-client-request-description-styles__descriptionToolbarBtn:hover {\n  color: #333333;\n}\n\n.src-client-request-client-request-description-styles__clientRequestAttachmentPadding {\n  background-color: white;\n  padding: 0 2.4rem 2rem;\n}", ""]);
// Exports
exports.locals = {
	"description": "src-client-request-client-request-description-styles__description",
	"descriptionTextBox": "src-client-request-client-request-description-styles__descriptionTextBox",
	"descriptionToolbar": "src-client-request-client-request-description-styles__descriptionToolbar",
	"descriptionToolbarBtn": "src-client-request-client-request-description-styles__descriptionToolbarBtn",
	"clientRequestAttachmentPadding": "src-client-request-client-request-description-styles__clientRequestAttachmentPadding"
};
module.exports = exports;
