// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-secondary-nav-editor-toolbar-styles__editorToolbarContainer {\n  left: 0;\n  width: 100%;\n  background-color: white;\n  display: -webkit-flex;\n  display: flex;\n  -webkit-flex-direction: row;\n  flex-direction: row;\n  justify-content: space-between;\n  min-height: 49px;\n  height: 49px;\n  align-content: center;\n  align-items: center;\n  box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.18);\n}\n\n.src-secondary-nav-editor-toolbar-styles__editorToolbarElement {\n  display: flex;\n  white-space: nowrap;\n}", ""]);
// Exports
exports.locals = {
	"editorToolbarContainer": "src-secondary-nav-editor-toolbar-styles__editorToolbarContainer",
	"editorToolbarElement": "src-secondary-nav-editor-toolbar-styles__editorToolbarElement"
};
module.exports = exports;
