import * as types from "./editor.types.js";
import { saveLetter, deleteLetter, saveTemplate, deleteTemplate } from "./editor.actions.js";
import { DEFAULT_FLASH_ICON_PLACEMENT, DEFAULT_EDITOR_LINE_HEIGHT } from "./editor.helper.js";
import draftReducer from "./draft.reducer.js";
import isSavedReducer from "./is-saved.reducer.js";
import { UPDATED_EDITOR_STATE } from "./draft.types.js";
import publishReducer from "./canopy-template/publish.reducer.js";

const DEFAULT_STATE = {
  documentLoaded: false,
  flashIconDisplayed: true,
  flashMenuDisplayed: false,
  shareWithClientMenuDisplayed: false,
  leftMenuToDisplay: null,
  clientRequestId: null,
  clientRequestDisplayed: false,
  fontMenuOpen: false,
  colorMenuOpen: false,
  type: null,
  document: null,
  unsavedTitleOfDocument: null,
  boilerplateText: null,
  mergeFields: null,
  mergeFieldsLookup: null,
  flashIconPlacementHelperData: { top: DEFAULT_FLASH_ICON_PLACEMENT, height: DEFAULT_EDITOR_LINE_HEIGHT },
};

function editorReducer(state = DEFAULT_STATE, action) {
  if (action.type === types.DOCUMENT_LOADING) {
    return {
      ...state,
      documentLoaded: false,
      clientRequestId: null,
      type: null,
      document: null,
      mergeFieldsLookup: null,
    };
  }

  if (action.type === types.DOCUMENT_LOADED) {
    return {
      ...state,
      documentLoaded: true,
      type: action.documentType,
      document: action.document,
      clientRequestId: action.clientRequestId,
      unsavedTitleOfDocument: action.document.title,
    };
  }

  if (action.type === types.UPDATED_FLASH_ICON_PLACEMENT) {
    return { ...state, flashIconPlacementHelperData: action.flashIconPlacementHelperData };
  }

  if (action.type === types.DOCUMENT_TITLE_CHANGED) {
    return { ...state, unsavedTitleOfDocument: action.document.title, document: { ...action.document } };
  }

  if (action.type === types.UNSAVED_TITLE_OF_DOCUMENT_CHANGED) {
    return { ...state, unsavedTitleOfDocument: action.title };
  }

  // Flash menu interactions
  if (action.type === types.FLASH_ICON_CLICKED) {
    return { ...state, flashMenuDisplayed: !state.flashMenuDisplayed };
  }

  if (action.type === types.FLASH_MENU_CLOSED) {
    return { ...state, flashIconDisplayed: true, flashMenuDisplayed: false };
  }

  if (action.type === types.FLASH_MENU_ITEM_SELECTED) {
    return { ...state, flashIconDisplayed: false, flashMenuDisplayed: false, leftMenuToDisplay: action.selectedTool };
  }

  // Left tool menu interactions
  if (action.type === types.LEFT_TOOL_MENU_CLOSED) {
    return { ...state, flashIconDisplayed: true, flashMenuDisplayed: false, leftMenuToDisplay: null };
  }

  if (action.type === types.SHARE_WITH_CLIENT_TOGGLED) {
    return { ...state, shareWithClientMenuDisplayed: !state.shareWithClientMenuDisplayed };
  }

  if (action.type === types.SHARE_MENU_CLOSED) {
    return { ...state, shareWithClientMenuDisplayed: false };
  }

  if (action.type === types.CLIENT_REQUEST_CREATED) {
    return { ...state, clientRequestId: action.requestId };
  }

  if (action.type === types.CLIENT_REQUEST_DIALOG_TOGGLED) {
    return {
      ...state,
      flashIconDisplayed: false,
      flashMenuDisplayed: false,
      leftMenuToDisplay: null,
      shareWithClientMenuDisplayed: false,
      clientRequestDisplayed: true,
    };
  }

  if (action.type === types.CLIENT_REQUEST_DIALOG_CLOSED) {
    return {
      ...state,
      flashIconDisplayed: true,
      flashMenuDisplayed: false,
      leftMenuToDisplay: null,
      shareWithClientMenuDisplayed: false,
      clientRequestDisplayed: false,
    };
  }

  if (action.type === types.BOILERPLATE_TEXT_LOADED) {
    return { ...state, boilerplateText: action.boilerplateText };
  }

  if (action.type === types.MERGE_FIELD_LOADED) {
    return { ...state, mergeFields: action.mergeFields };
  }

  if (action.type === types.MERGE_FIELD_VALUES_LOADED) {
    return { ...state, mergeFieldValues: action.mergeFieldValues };
  }

  if (action.type === types.FONT_MENU_OPENED) {
    return { ...state, fontMenuOpen: true };
  }

  if (action.type === types.FONT_MENU_CLOSED) {
    return { ...state, fontMenuOpen: false };
  }

  if (action.type === types.COLOR_MENU_OPENED) {
    return { ...state, colorMenuOpen: true };
  }

  if (action.type === types.COLOR_MENU_CLOSED) {
    return { ...state, colorMenuOpen: false };
  }

  if (action.type === types.APP_UNLOADED) {
    return DEFAULT_STATE;
  }

  if (action.type === types.SIGNING_MODAL_TOGGLED) {
    return { ...state, signingModalDisplayed: action.displayed };
  }

  if (action.type === types.SET_NOTIFICATION_IDS) {
    return { ...state, notificationIds: action.notificationIds };
  }

  return state;
}

export default function (state, action) {
  state = editorReducer(state, action);
  const editorState = draftReducer(state.editorState, action);
  const publishState = publishReducer(state.publishState, action);
  return {
    ...state,
    editorState,
    publishState,
    isSaved: isSavedReducer(state.isSaved, action, {
      old: state.editorState,
      fresh: editorState,
    }),
  };
}
