// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-left-pane-tools-tool-menu-left-styles__sidePane {\n  min-width: 20rem;\n  position: fixed;\n  top: 18.3rem;\n  transition: 0.5s left;\n  height: calc(100% - 18.3rem);\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.src-left-pane-tools-tool-menu-left-styles__sidePaneContainer {\n  padding: 0.8rem;\n  margin-left: 2.4rem;\n  position: relative;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  z-index: 51;\n}\n\n.src-left-pane-tools-tool-menu-left-styles__sidePaneTitle {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.src-left-pane-tools-tool-menu-left-styles__sidePanelEmptyState #es-headText {\n  font-size: 16px !important;\n  font-weight: 500 !important;\n}\n", ""]);
// Exports
exports.locals = {
	"sidePane": "src-left-pane-tools-tool-menu-left-styles__sidePane",
	"sidePaneContainer": "src-left-pane-tools-tool-menu-left-styles__sidePaneContainer",
	"sidePaneTitle": "src-left-pane-tools-tool-menu-left-styles__sidePaneTitle",
	"sidePanelEmptyState": "src-left-pane-tools-tool-menu-left-styles__sidePanelEmptyState"
};
module.exports = exports;
