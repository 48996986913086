export const USER_LOGGED_IN = "USER_LOGGED_IN";

export const DOCUMENT_LOADING = "DOCUMENT_LOADING";
export const DOCUMENT_LOADED = "DOCUMENT_LOADED";
export const DOCUMENT_SAVED = "DOCUMENT_SAVED";
export const DOCUMENT_DELETED = "DOCUMENT_DELETED";
export const DOCUMENT_TITLE_CHANGED = "DOCUMENT_TITLE_CHANGED";
export const UNSAVED_TITLE_OF_DOCUMENT_CHANGED = "UNSAVED_TITLE_OF_DOCUMENT_CHANGED";
export const PRINT = "PRINT";

export const LOADED_EDITOR_STATE = "LOADED_EDITOR_STATE";
export const FONT_MENU_OPENED = "FONT_MENU_OPENED";
export const FONT_MENU_CLOSED = "FONT_MENU_CLOSED";
export const COLOR_MENU_OPENED = "COLOR_MENU_OPENED";
export const COLOR_MENU_CLOSED = "COLOR_MENU_CLOSED";

export const FLASH_ICON_CLICKED = "FLASH_ICON_CLICKED";
export const FLASH_MENU_CLOSED = "FLASH_MENU_CLOSED";
export const FLASH_MENU_ITEM_SELECTED = "FLASH_MENU_ITEM_SELECTED";

export const LEFT_TOOL_MENU_ITEM_SELECTED = "LEFT_TOOL_MENU_ITEM_SELECTED";
export const LEFT_TOOL_MENU_CLOSED = "LEFT_TOOL_MENU_CLOSED";

export const SHARE_WITH_CLIENT_TOGGLED = "SHARE_WITH_CLIENT_TOGGLED";
export const SHARE_MENU_CLOSED = "SHARE_MENU_CLOSED";
export const CLIENT_REQUEST_CREATED = "CLIENT_REQUEST_CREATED";
export const CLIENT_REQUEST_DIALOG_TOGGLED = "CLIENT_REQUEST_DIALOG_TOGGLED";
export const CLIENT_REQUEST_DIALOG_CLOSED = "CLIENT_REQUEST_DIALOG_CLOSED";

export const BOILERPLATE_TEXT_LOADED = "BOILERPLATE_TEXT_LOADED";
export const BOILERPLATE_TEXT_INSERTED = "BOILERPLATE_TEXT_INSERTED";

export const MERGE_FIELD_LOADED = "MERGE_FIELD_LOADED";
export const MERGE_FIELD_VALUES_LOADED = "MERGE_FIELD_VALUES_LOADED";
export const MERGE_FIELD_INSERTED = "MERGE_FIELD_INSERTED";

export const BLOCK_REMOVED = "BLOCK_REMOVED";
export const UPDATED_FLASH_ICON_PLACEMENT = "UPDATED_FLASH_ICON_PLACEMENT";
export const APP_UNLOADED = "APP_UNLOADED";

export const SIGNING_MODAL_TOGGLED = "SIGNING_MODAL_TOGGLED";
export const SET_NOTIFICATION_IDS = "SET_NOTIFICATION_IDS";
