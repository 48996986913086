import React from "react";
import { CprDatepicker } from "canopy-styleguide!sofe";

import styles from "./client-request-reminders.styles.css";

export default class DatePicker extends React.Component {
  render() {
    const getDateClass = () => {
      return this.props.date ? styles.withDate : styles.withoutDate;
    };
    return (
      <CprDatepicker
        id="client-request-datepicker"
        events={{
          datechange: this.handleDateChange,
        }}
        orientation="bottom left"
        placeholder="Add a due date"
        inputClass={`${styles.dottedUnderline} ${styles.placeholder} ${styles.dateInput}
          ${getDateClass()} cps-form-control`}
        date={this.props.date}
      />
    );
  }

  handleDateChange = (e) => {
    this.props.actions.setDueDate(e.detail ? e.detail.getTime() : null);
  };
}
