import { of } from "rxjs";
import { pluck, switchMap } from "rxjs/operators";

import canopyUrls from "canopy-urls!sofe";
import { fetchAsObservable } from "fetcher!sofe";

export function getLetter(clientId, resolutionCaseId, letterId) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/letters/${letterId}`
  ).pipe(switchMap(toCacheBustedPreviewUrl));
}

export function persistLetter(clientId, resolutionCaseId, letter) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/letters/${letter.id}`,
    { method: "PUT", body: `{"letters": ${JSON.stringify(letter)}}` }
  );
}

export function removeLetter(clientId, resolutionCaseId, letterId) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/letters/${letterId}`,
    { method: "DELETE" }
  );
}

export function getTemplates() {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/templates/letters`);
}

export function getCanopyTemplates() {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/letters/canopy-templates`).pipe(pluck("letters"));
}

export const createAccusoftTemplate = (templateTitle) => {
  return fetchAsObservable(`/api/letters/letter_templates`, {
    method: "POST",
    body: {
      title: templateTitle,
    },
  }).pipe(pluck("letters"));
};

export function postTemplate(template) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/templates/letters`, {
    method: "POST",
    body: `{"letters": ${JSON.stringify(template)}}`,
  }).pipe(pluck("letters"));
}

export function postCanopyTemplate(canopy_template) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/letters/canopy-templates`, {
    method: "POST",
    body: { canopy_template },
  }).pipe(pluck("template"));
}

export const uploadDocumentTemplate = (document) => {
  const file = new FormData();
  file.append("document", document);

  return fetchAsObservable(`/api/letters/letter_templates/from-file?title=${document.name}`, {
    method: "POST",
    body: file,
  });
};

export function getTemplate(templateId) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/templates/letters/${templateId}`).pipe(
    switchMap(toCacheBustedPreviewUrl)
  );
}

export function getCanopyTemplate(templateId) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/letters/canopy-templates/${templateId}`).pipe(
    pluck("template")
  );
}

export function persistTemplate(template) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/templates/letters/${template.id}`, {
    method: "PUT",
    body: `{"letters": ${JSON.stringify(template)}}`,
  });
}

export function persistCanopyTemplate(canopy_template) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/letters/canopy-templates/${canopy_template.id}`, {
    method: "PATCH",
    body: { canopy_template },
  }).pipe(pluck("template"));
}

export function removeTemplate(templateId) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/templates/letters/${templateId}`, { method: "DELETE" });
}

export function removeCanopyTemplate(templateId) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/letters/canopy-templates/${templateId}`, {
    method: "DELETE",
  });
}

export function getLetterPreview(clientId, resolutionCaseId, letterId) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/letters/${letterId}/svgs`
  );
}

export function getTemplatePreview(templateId) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/templates/letters/${templateId}/svgs`);
}

export function getCanopyTemplatePreview(canopyTemplateId) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/letters/canopy-templates/${canopyTemplateId}/svg`);
}

export function updateTemplateToDocx(templateId) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/templates/letters/${templateId}/generate_docx`);
}

function toCacheBustedPreviewUrl(response) {
  return of({
    ...response.letters,
    preview_image_url: `${response.letters.preview_image_url}?q=${new Date().getTime()}`,
  });
}
