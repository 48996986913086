// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n\n.src-admin-boilerplate-styles__table {\n  border: #e9e9e9;\n  border-style: solid;\n  border-width: .1rem;\n}\n\n.src-admin-boilerplate-styles__ellipsis {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.src-admin-boilerplate-styles__hoverIcon {\n  visibility: hidden;\n}\n\n.src-admin-boilerplate-styles__row:hover {\n  background-color: #f7f7f7;\n  cursor: pointer;\n}\n\n.src-admin-boilerplate-styles__row:hover .src-admin-boilerplate-styles__hoverIcon {\n  visibility: visible;\n}\n\n.src-admin-boilerplate-styles__actionCol {\n  flex: 2;\n  padding-left: 0;\n  padding-top: 8px;\n  padding-bottom: 8px;\n}\n\n.src-admin-boilerplate-styles__lightBorder {\n  border-style: solid;\n  border-color: #e9e9e9;\n  border-radius: .5rem;\n  border-width: 1px;\n}\n\n.src-admin-boilerplate-styles__trash {\n  visibility: hidden;\n}\n\n.src-admin-boilerplate-styles__slat:hover .src-admin-boilerplate-styles__trash {\n  visibility: visible;\n}\n", ""]);
// Exports
exports.locals = {
	"table": "src-admin-boilerplate-styles__table",
	"ellipsis": "src-admin-boilerplate-styles__ellipsis",
	"hoverIcon": "src-admin-boilerplate-styles__hoverIcon",
	"row": "src-admin-boilerplate-styles__row",
	"actionCol": "src-admin-boilerplate-styles__actionCol",
	"lightBorder": "src-admin-boilerplate-styles__lightBorder",
	"trash": "src-admin-boilerplate-styles__trash",
	"slat": "src-admin-boilerplate-styles__slat"
};
module.exports = exports;
