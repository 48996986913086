import React from "react";

import styles from "./client-request-due-date.styles.css";
import DatePicker from "./date.component.js";

export default function ClientRequestDueDate({ date, actions }) {
  return (
    <div className={`${styles.dueDate}`}>
      <div className={`${styles.dueDateLabel}`}>Due Date:</div>
      <div>
        <DatePicker date={date} actions={actions} />
      </div>
      <button
        onClick={actions.removeDueDate}
        className={`${styles.removeButton} cps-link`}
        style={{ display: date ? "block" : "none" }}
      >
        REMOVE
      </button>
    </div>
  );
}
