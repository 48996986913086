import React from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import { UserTenantProps } from "cp-client-auth!sofe";

import contextReducer from "./bootstrap/context.reducer.js";
import letterEditorReducer from "./editor/editor.reducer.js";
import clientRequestReducer from "./client-request/client-request.reducer.js";
import previewReducer from "./preview/preview.reducer.js";
import { loginUser } from "./bootstrap/context.actions.js";
import AppContainer from "./app.container.js";

import LetterEditorContainer from "./editor/letter-editor.container.js";
import TemplateEditorContainer from "./editor/template-editor.container.js";
import BoilerplateContainer from "./admin/boilerplate.container.js";
import TemplatesContainer from "./admin/templates.container.js";

let store;

export function initialize(loggedInUser, tenant) {
  const reducer = combineReducers({
    context: contextReducer,
    letterEditor: letterEditorReducer,
    clientRequest: clientRequestReducer,
    preview: previewReducer,
  });

  store = createStore(reducer, applyMiddleware(thunk));
  store.dispatch(loginUser(loggedInUser, tenant));
}

@UserTenantProps({
  permissions: {
    userHasLT: "templates_letters",
    userHasBoiler: "templates_boilerplate_text",
    userHasLetters: "letters",
  },
  waitForData: true,
})
export default class LettersRoot extends React.Component {
  render() {
    if (window.location.href.includes("engagements")) {
      // Remove this when we add engagements routes
      window.location.replace(window.location.href.replace("engagements", "resolution-cases"));
    }
    const { permissions } = this.props;
    return (
      <Provider store={store}>
        <HashRouter>
          {/* AppContainer should always display */}
          {!permissions.userHasLetters && <Redirect to="/403" />}
          <Route
            render={(props) => (
              <AppContainer {...props}>
                <Switch>
                  <PrivateRoute
                    path={["/letters/edit/template/:templateId", "/letters/edit/canopy-template/:templateId"]}
                    permitted={permissions.userHasLT}
                  >
                    <TemplateEditorContainer />
                  </PrivateRoute>
                  <Route
                    path="/letters/edit/client/:clientId/resolution-cases/:resolutionCaseId/letter/:letterId"
                    component={LetterEditorContainer}
                  />
                  <PrivateRoute path="/letters/global-settings/templates" permitted={permissions.userHasLT}>
                    <TemplatesContainer />
                  </PrivateRoute>
                  <PrivateRoute path="/letters/global-settings/boilerplate" permitted={permissions.userHasBoiler}>
                    <BoilerplateContainer />
                  </PrivateRoute>
                </Switch>
              </AppContainer>
            )}
          />
        </HashRouter>
      </Provider>
    );
  }
}

const PrivateRoute = ({ children, permitted, ...rest }) => (
  <Route
    {...rest}
    render={({ location, match }) =>
      permitted ? React.cloneElement(children, { location, match }) : <Redirect to="/403" />
    }
  />
);
