import canopyUrls from "canopy-urls!sofe";
import { fetchAsObservable } from "fetcher!sofe";

export function createClientRequest(clientId, resolutionCaseId) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/requests/`,
    { method: "POST", body: `{"client_requests":{}}` }
  );
}

export function getClientRequest(clientId, resolutionCaseId, requestId) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/requests/${requestId}`,
    { method: "GET" }
  );
}

export function updateClientRequest(clientId, resolutionCaseId, requestId, clientRequest) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/requests/${requestId}`,
    { method: "PUT", body: `${JSON.stringify(clientRequest)}` }
  );
}

export function attachLetterToClientRequest(clientId, requestId, letterName, letterId) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/attachments?pivot_id=${requestId}&pivot_type=client_requests&visible_to_client=true&create_from_type=letter&create_from_id=${letterId}`,
    { method: "POST" }
  );
}

export function getAttachments(clientId, requestId) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/attachments?pivot_type=client_requests&pivot_id=${requestId}`,
    { method: "GET" }
  );
}

export function deleteAttachment(clientId, attachementId) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/attachments/${attachementId}`, {
    method: "DELETE",
  });
}

export function sendClientRequest(clientId, body) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/requests`, {
    method: "PUT",
    body: body,
  });
}
