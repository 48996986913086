import React from "react";
import ServiceMultiSelect from "./service-multiselect.component";
import PublishStatus from "./publish-status.component";
import styles from "./publish.styles.css";
import { connect } from "react-redux";
import { Dialog } from "primary-navbar!sofe";
import { SUBMITTED, PUBLISHED } from "./publish.constants";
import {
  updateCanopyTemplateDescription,
  updateCanopyTemplateServiceSlugs,
  submitForReview,
  publish,
  reject,
  unpublish,
} from "./publish.actions";

const maxLength = 51;

@connect((state) => ({
  template: state.letterEditor.document,
  ...state.letterEditor.publishState,
}))
export default class Publish extends React.Component {
  state = {
    openUnpublishConfirmModal: false,
  };
  toggleUnpublishConfirmModal = () =>
    this.setState({ openUnpublishConfirmModal: !this.state.openUnpublishConfirmModal });
  updateDescription = (e) => this.props.dispatch(updateCanopyTemplateDescription(e.target.value));
  updateServiceSlugs = (slugs) => this.props.dispatch(updateCanopyTemplateServiceSlugs(slugs));
  submit = () => this.props.dispatch(submitForReview());
  publish = () => this.props.dispatch(publish());
  reject = () => this.props.dispatch(reject());
  unpublish = () => this.props.dispatch(unpublish());
  render() {
    const { status, copied_from_template_id } = this.props.template;
    return (
      <div>
        <PublishStatus status={status} hasPublishedParent={!!copied_from_template_id} />
        <div className="cps-margin-top-8">
          <div>
            <label htmlFor="services">Services</label>
          </div>
          <ServiceMultiSelect defaultSelectedItem={this.props.service_slugs} onChange={this.updateServiceSlugs} />
        </div>
        <div className={`${styles.editorPublishSplitGroup} cps-margin-top-8`}>
          <label htmlFor="letterDescription">Description</label>
          <span>
            <span className="cps-caption cps-gray1">({maxLength - this.props.description.length} characters left)</span>
          </span>
        </div>
        <textarea
          name="letterDescription"
          id="letterDescription"
          rows="3"
          className="cps-form-control cps-margin-top-4"
          placeholder="Enter a short description"
          style={{ minWidth: "100%" }}
          maxLength={maxLength}
          value={this.props.description}
          onChange={this.updateDescription}
        ></textarea>
        <div className={`${styles.editorPublishSplitGroup} cps-margin-top-16`}>
          {!this.props.canPublish && status !== PUBLISHED && status !== SUBMITTED && (
            <button
              className="cps-btn +primary"
              type="button"
              disabled={!(this.props.description && this.props.service_slugs.length) || this.props.submitting}
              onClick={this.submit}
            >
              Submit changes
            </button>
          )}
          {this.props.canPublish && status === SUBMITTED && (
            <div>
              <button
                className="cps-btn +primary"
                type="button"
                onClick={this.publish}
                disabled={this.props.submitting}
              >
                Publish
              </button>
              <button
                className="cps-btn +secondary"
                type="button"
                onClick={this.reject}
                disabled={this.props.submitting}
              >
                Reject
              </button>
            </div>
          )}
          {this.props.canPublish && status === PUBLISHED && (
            <button
              className="cps-btn +secondary"
              type="button"
              disabled={this.props.submitting}
              onClick={this.toggleUnpublishConfirmModal}
            >
              Unpublish
            </button>
          )}
        </div>
        {this.state.openUnpublishConfirmModal && (
          <Dialog>
            <div className="cps-modal">
              <div className="cps-modal__screen"></div>
              <div className="cps-modal__dialog cps-card__height-2">
                <div className="cps-card__header cps-subheader-sm">
                  <span>Unpublish letter template</span>
                  <button
                    type="button"
                    className="cps-modal__dialog__close cps-btn-icon cps-link cps-padding-0"
                    onClick={this.toggleUnpublishConfirmModal}
                  >
                    <span className="cps-icon cps-icon-close"></span>
                    <span className="cps-hide-visually">Cancel unpublish and close modal</span>
                  </button>
                </div>
                <div className="cps-card__body">Are you sure you want to unpublish this Canopy letter template?</div>
                <div className="cps-modal__dialog__actions">
                  <button
                    className="cps-btn +primary"
                    onClick={() => {
                      this.unpublish();
                      this.toggleUnpublishConfirmModal();
                    }}
                  >
                    Unpublish
                  </button>
                  <button type="button" className="cps-link" onClick={this.toggleUnpublishConfirmModal}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
        )}
      </div>
    );
  }
}
