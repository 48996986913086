import React from "react";
import { EditorState } from "draft-js";

import styles from "./secondary-nav.styles.css";

export default function SaveAndExit(props) {
  return (
    <div>
      <a
        onClick={() => {
          props.actions.loadPreview(props.params);
          props.actions.saveDocument(true);
        }}
        className="cps-link cps-light-gray"
      >
        <span className="cps-icon cps-icon-arrow-left"></span> &nbsp; <span>Save and exit</span>
      </a>
    </div>
  );
}
