// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-editor-canopy-template-publish-styles__editorPublishSplitGroup {\n  display: flex;\n  justify-content: space-between;\n}", ""]);
// Exports
exports.locals = {
	"editorPublishSplitGroup": "src-editor-canopy-template-publish-styles__editorPublishSplitGroup"
};
module.exports = exports;
