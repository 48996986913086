import React from "react";
import { handleError } from "src/handle-error.helper.js";
import { sortBy, without } from "lodash";
import toasts from "toast-service!sofe";
import { CprEmptyState } from "canopy-styleguide!sofe";

import EditCategory from "./edit-category.component.js";
import EditBoilerplate from "./edit-boilerplate.component.js";
import {
  postBoilerplateItem,
  putBoilerplateItem,
  deleteBoilerplateItem,
} from "../left-pane-tools/boilerplate.resource.js";

import styles from "./boilerplate.styles.css";

export default class Category extends React.Component {
  constructor() {
    super();
    this.state = {
      editBoilerplate: null,
      newBoilerplate: false,
      hide: [],
    };

    this.openNewBoilerplateDialog = () => this.setState({ newBoilerplate: true });
    this.closeNewBoilerplateDialog = () => this.setState({ newBoilerplate: false });

    this.editBoilerplate = (boilerplate) => this.setState({ editBoilerplate: boilerplate });
    this.stopEditingBoilerplate = () => this.setState({ editBoilerplate: null });
    this.unloaded = false;
  }

  componentWillUnmount() {
    this.unloaded = true;
  }

  createBoilerplate(boilerplate) {
    postBoilerplateItem({
      ...boilerplate,
      relationships: {
        category: {
          type: "boilerplate_categories",
          id: this.props.category.id,
        },
      },
    }).subscribe(this.props.updateCategories, handleError);

    this.closeNewBoilerplateDialog();
  }

  saveBoilerplate(boilerplate) {
    boilerplate = { ...boilerplate };
    delete boilerplate.type;

    putBoilerplateItem({
      ...boilerplate,
      relationships: {
        category: {
          type: "boilerplate_categories",
          id: this.props.category.id,
        },
      },
    }).subscribe(this.props.updateCategories, handleError);

    this.stopEditingBoilerplate();
  }

  removeBoilerplate(boilerplate) {
    let timeout = setTimeout(() => {
      deleteBoilerplateItem(boilerplate).subscribe(this.props.updateCategories, handleError);
    }, 3000);

    this.setState({
      hide: [...this.state.hide, boilerplate.id],
    });

    toasts.successToast(`Boilerplate ${boilerplate.title} deleted`, "Undo", () => {
      clearTimeout(timeout);

      if (!this.unloaded) {
        this.setState({
          hide: without(this.state.hide, boilerplate.id),
        });
      }
      toasts.successToast("Boilerplate restored successfully");
    });

    this.stopEditingBoilerplate();
  }

  render() {
    const { title, relationships } = this.props.category;
    const { editBoilerplate, newBoilerplate } = this.state;
    const { editing, editCategory, category, stopEditingCategory, saveCategory, removeCategory } = this.props;
    const boilerplates = (relationships.boilerplates || []).filter(
      (boilerplate) => !this.state.hide.find((id) => id === boilerplate.id)
    );

    return (
      <div className="cps-margin-bottom-24">
        {editing && (
          <EditCategory category={category} save={saveCategory} close={stopEditingCategory} remove={removeCategory} />
        )}
        {editBoilerplate && (
          <EditBoilerplate
            boilerplate={editBoilerplate}
            save={this.saveBoilerplate.bind(this)}
            close={this.stopEditingBoilerplate}
          />
        )}
        {newBoilerplate && (
          <EditBoilerplate save={this.createBoilerplate.bind(this)} close={this.closeNewBoilerplateDialog} />
        )}
        <div style={{ height: 36 }}>
          <span
            onClick={editCategory}
            className="cps-body-sm cps-cursor-pointer"
            style={{ verticalAlign: "-webkit-baseline-middle" }}
          >
            {title}
          </span>
          <button onClick={editCategory} className="cps-link cps-light-gray cps-pull-right">
            Edit category
          </button>
          {boilerplates.length ? (
            <button onClick={this.openNewBoilerplateDialog} className="cps-link cps-pull-right">
              + Add boilerplate text
            </button>
          ) : null}
        </div>
        {!boilerplates.length ? (
          <div className={`${styles.lightBorder}`}>
            <CprEmptyState
              iconSrc="es_boilerplate_text"
              iconSize="large"
              headText="Add a boilerplate"
              subheadText="Add your first boilerplate to this category"
              showCta={true}
              ctaClickHandler={this.openNewBoilerplateDialog}
              ctaButtonText="Add boilerplate"
            />
          </div>
        ) : null}
        {boilerplates.length ? (
          <div className={`cp-inline-table-with-border ${styles.table}`}>
            <div className="cps-flex-table">
              <div className="cps-flex-table__header">
                <div className="cps-flex-table__col" style={{ flex: 4 }}>
                  Boilerplate Title
                </div>
                <div className="cps-flex-table__col" style={{ flex: 10 }}>
                  Boilerplate Text Preview
                </div>
                <div className={`cps-flex-table__col ${styles.actionCol}`}></div>
              </div>
              {sortBy(boilerplates, "title").map((boilerplate, index) => (
                <div key={index} className={`cps-flex-table__row ${styles.row}`}>
                  <div
                    onClick={this.editBoilerplate.bind(this, boilerplate)}
                    className="cps-flex-table__col"
                    style={{ flex: 4 }}
                  >
                    {boilerplate.title}
                  </div>
                  <div
                    onClick={this.editBoilerplate.bind(this, boilerplate)}
                    className={`cps-flex-table__col ${styles.ellipsis}`}
                    style={{ flex: 10 }}
                  >
                    {boilerplate.body}
                  </div>
                  <div className={`cps-flex-table__col ${styles.actionCol}`}>
                    <a
                      onClick={this.removeBoilerplate.bind(this, boilerplate)}
                      className="cps-padding-left-16"
                      style={{ float: "right" }}
                    >
                      <i className={`cps-icon cps-icon-trash ${styles.hoverIcon}`}></i>
                    </a>
                    <a onClick={this.editBoilerplate.bind(this, boilerplate)} style={{ float: "right" }}>
                      <i className={`cps-icon cps-icon-notes ${styles.hoverIcon}`}></i>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
