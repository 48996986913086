import { of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { fetchAsObservable } from "fetcher!sofe";
import canopyUrls from "canopy-urls!sofe";

export function getUsersFromClient(clientId) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}?include=users`, {
    method: "GET",
  }).pipe(switchMap((json) => of(json.clients.users)));
}
