import React, { useState } from "react";
import { CpModal, CpInput, CpButton } from "canopy-styleguide!sofe";

export const CreateTemplate = ({ show, onClose, onSave }) => {
  const [templateTitle, setTemplateTitle] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const save = () => {
    setShowLoader(true);
    onSave({ title: templateTitle });
  };

  const onAfterClose = () => {
    setShowLoader(false);
    setTemplateTitle("");
  };

  return (
    <CpModal show={show} onClose={onClose} onAfterClose={onAfterClose}>
      <CpModal.Header title="Create a Template" />
      <CpModal.Body>
        <CpInput
          label="Template Name"
          maxLength="200"
          autoFocus
          placeholder="Template name"
          className="cp-mb-16"
          value={templateTitle}
          onChange={setTemplateTitle}
        />
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={save}
          showLoader={showLoader}
          disabled={!templateTitle}
          className="cp-mr-8"
        >
          Save template
        </CpButton>
        <CpButton btnType="flat" disabled={showLoader} onClick={onClose}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
};
