import * as types from "./preview.types.js";
import * as editorTypes from "../editor/editor.types.js";

const DEFAULT_STATE = {
  previewLoading: false,
  previewDisplayed: false,
  svgs: [],
};

export default function (state = DEFAULT_STATE, action) {
  if (action.type === types.PREVIEW_LOADING) {
    return {
      ...state,
      previewLoading: true,
      previewDisplayed: true,
    };
  }

  if (action.type === types.PREVIEW_LOADED) {
    return {
      ...state,
      previewLoading: false,
      svgs: action.svgs,
    };
  }

  if (action.type === types.PREVIEW_CLOSED) {
    return {
      ...state,
      previewLoading: false,
      previewDisplayed: false,
    };
  }

  if (action.type === editorTypes.APP_UNLOADED) {
    return DEFAULT_STATE;
  }

  return state;
}
