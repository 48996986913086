import React from "react";
import styles from "./preview.styles.css";
import { CpLoader } from "canopy-styleguide!sofe";

export default class Preview extends React.Component {
  render() {
    const { preview } = this.props;

    return preview.previewLoading ? this.renderLoading() : this.renderPages();
  }

  renderPages() {
    return (
      <div className={`${styles.previewPages}`}>
        {this.props.preview.svgs.map((svg, index) => {
          return (
            <div key={index} className="cps-card cps-margin-bottom-24" style={{ minHeight: "1056px" }}>
              <img src={svg.url} />
            </div>
          );
        })}
      </div>
    );
  }

  renderLoading() {
    return (
      <div className={`cps-card ${styles.loadingWrapper}`}>
        <div className={`${styles.loader}`}>
          <CpLoader page="true" data-testid="test-loader" />
        </div>
      </div>
    );
  }
}
