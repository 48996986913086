import canopyUrls from "canopy-urls!sofe";
import { fetchAsObservable } from "fetcher!sofe";

export function postLetterToFiles(clientId, letterId, notificationURI, clientUserIds = []) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/attachments?create_from_type=letter&create_from_id=${letterId}&visible_to_client=true&notifications=${clientUserIds.join(
      ","
    )}&notification_url=${notificationURI}`,
    { method: "POST" }
  );
}

export function deleteAttachment(clientId, fileId) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/docs/clients/${clientId}/files/${fileId}`, {
    method: "DELETE",
  });
}
