import toasts from "toast-service!sofe";
import { postLetterToFiles, deleteAttachment } from "./file-upload.resource.js";

import { getClientUsers } from "./client-users.resource.js";

import { pull } from "lodash";

export function shareLetter(clientId, letterId, currentUserId) {
  getClientUsers(clientId).subscribe((response) => {
    let clientUserIds = response.users.map((user) => user.id);
    let notificationURI = window.location.origin + `/#/docs/clients/${clientId}/files`;

    postLetterToFiles(
      clientId,
      letterId,
      encodeURIComponent(notificationURI),
      pull(clientUserIds, currentUserId)
    ).subscribe((response) => {
      toasts.successToast(
        "Letter shared to client files and visible to client",
        "Undo",
        deleteFile.bind(null, clientId, response.attachments[0].id)
      );
    });
  });
}

export function deleteFile(clientId, attachementId) {
  deleteAttachment(clientId, attachementId).subscribe((response) => {
    toasts.successToast("Removed document from client files");
  });
}
