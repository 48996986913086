import {
  convertToRaw,
  SelectionState,
  DefaultDraftInlineStyle,
  EditorState,
  Modifier,
  AtomicBlockUtils,
} from "draft-js";
import createImagePlugin from "draft-js-image-plugin";
import { forEach, includes, map, reduce } from "lodash";
import { convertToHTML, convertFromHTML } from "draft-convert";
import defaultBlock from "draft-convert/lib/default/defaultBlockHTML.js";
import moveAtomicBlock from "./move-atomic-block.helper.js";

export function removeBlock(action, editorState, SelectionState) {
  const targetRange = new SelectionState({
    anchorKey: action.block.getKey(),
    anchorOffset: 0,
    focusKey: action.block.getKey(),
    focusOffset: action.block.getLength(),
  });

  const withoutBlock = Modifier.removeRange(editorState.getCurrentContent(), targetRange, "backward");

  const resetBlock = Modifier.setBlockType(withoutBlock, withoutBlock.getSelectionAfter(), "unstyled");

  const newState = EditorState.push(editorState, resetBlock, "remove-range");

  return EditorState.forceSelection(newState, resetBlock.getSelectionAfter());
}

export function insertPastedContent({ text, html }, editorState) {
  const isHtml = /<[a-z][\s\S]*>/i.test(html);

  const selectionState = editorState.getSelection();

  let contentState;

  if (isHtml) {
    contentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      selectionState,
      convertFromHTML(html).getBlockMap()
    );
  } else {
    contentState = contentState = Modifier.replaceText(editorState.getCurrentContent(), selectionState, text);
  }

  return EditorState.createWithContent(contentState, editorState.getDecorator());
}

export function insertMergeField(action, editorState) {
  if (!action.allMergeFields) {
    return editorState;
  }

  const selectionStateOrig = editorState.getSelection();
  let entityKey = null,
    textToInsert = "",
    allMergeFields = [];

  // get all the merge fields into one, flat structure
  forEach(action.allMergeFields, (category) => {
    category.map((categoryElement) => {
      allMergeFields.push(categoryElement);
    });
  });

  // Collapse the selection before performing inserts of mergeFieldComponents
  let contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    selectionStateOrig,
    textToInsert,
    null,
    entityKey
  );

  const collapsedSelectionState = contentState.getSelectionAfter();
  const mergeFieldComponents = action.mergeField.format.split(/(\$\{[\w|-]+\})/gi);

  // loop through in reverse to avoid worrying about changing index positions
  for (let i = mergeFieldComponents.length - 1; i >= 0; i--) {
    const mergeFieldKey = getMergeFieldKeyFromDelimiter(mergeFieldComponents[i]);
    entityKey = null;

    if (mergeFieldKey) {
      const mergeField = allMergeFields.find((mergeField) => mergeField.key === mergeFieldKey);
      contentState = contentState.createEntity("MERGE_FIELD", "IMMUTABLE", { field: mergeField });
      entityKey = contentState.getLastCreatedEntityKey();
      textToInsert = mergeField.insertText || "";
    } else {
      textToInsert = mergeFieldComponents[i] || "";
    }

    contentState = Modifier.insertText(contentState, collapsedSelectionState, textToInsert, null, entityKey);
  }

  // helper function to get the MergeField key from the delimiter
  function getMergeFieldKeyFromDelimiter(delimiter) {
    const re = /[^\$\{](\w|-)*?(?=\})/gi;

    return re.test(delimiter) ? delimiter.match(re)[0] : false;
  }

  // Finally, return the new EditorState
  return EditorState.forceSelection(
    EditorState.createWithContent(contentState, editorState.getDecorator()),
    contentState.getSelectionAfter()
  );
}

export function replaceEntitiesWithCRMValues(editorContentState, rawContentState, mergeFieldValues) {
  return replaceEntity(editorContentState, rawContentState, mergeFieldValues);
}

export function replaceEntity(editorContentState, rawContentState, mergeFieldValues) {
  let block = rawContentState.blocks.find((block) => getMergeTagEntity(rawContentState, block));

  if (!block) {
    return editorContentState;
  }

  let entitySelectionState = new SelectionState({
    anchorKey: block.key,
    anchorOffset: block.entityRanges[0].offset,
    focusKey: block.key,
    focusOffset: block.entityRanges[0].offset + block.entityRanges[0].length,
    isBackward: false,
  });

  let entity = getMergeTagEntity(rawContentState, block);

  let replacementText = mergeFieldValues[entity.data.field.key] || `<${entity.data.field.insertText} Missing>`;

  let offsetStyles = editorContentState.getBlockForKey(block.key).getInlineStyleAt(block.entityRanges[0].offset - 1);

  let newContentState = Modifier.replaceText(editorContentState, entitySelectionState, replacementText, offsetStyles);

  return replaceEntity(newContentState, convertToRaw(newContentState), mergeFieldValues);
}

export function hasUnmergedTags(rawBody) {
  if (!rawBody) return false;
  const rawContentState = JSON.parse(rawBody);

  let block = rawContentState.blocks.find((block) => getMergeTagEntity(rawContentState, block));

  return !!block;
}

function getMergeTagEntity(rawContentState, block) {
  return reduce(
    block.entityRanges,
    (entity, range) => {
      if (entity) return entity;
      if (rawContentState.entityMap[range.key].type === "MERGE_FIELD") {
        return rawContentState.entityMap[range.key];
      }
    },
    null
  );
}

export function getStyle(block) {
  if (!block.inlineStyleRanges || !block.inlineStyleRanges.length) return {};

  let styleString;

  if (!block.inlineStyleRanges[1]) styleString = block.inlineStyleRanges[0].style;
  else styleString = getStyleFromFirstText(block.inlineStyleRanges).style;

  const style = styleString.substring(0, styleString.length - 2) * 1;

  return { margin: style > 12 ? style + style / 2 + "pt" : "0pt", text: styleString };

  function getStyleFromFirstText(inlineStyleRanges) {
    let first, second, t, rest;
    if (inlineStyleRanges.length > 2) {
      [first, second, ...rest] = inlineStyleRanges;
    } else {
      [first, second] = inlineStyleRanges;
    }

    if (first.offset === second.offset && first.length === second.length) {
      if (rest) {
        return getStyleFromFirstText([second, ...rest]);
      } else {
        return second;
      }
    } else {
      return first;
    }
  }
}

export function generateHtml(content) {
  const html = convertToHTML({
    styleToHTML: {
      "8pt": {
        start: `<span style='font-size: 8pt;'>`,
        end: `</span>`,
      },
      "10pt": {
        start: `<span style='font-size: 10pt;'>`,
        end: `</span>`,
      },
      "12pt": {
        start: `<span style='font-size: 12pt;'>`,
        end: `</span>`,
      },
      "14pt": {
        start: `<span style='font-size: 14pt;'>`,
        end: `</span>`,
      },
      "18pt": {
        start: `<span style='font-size: 18pt;'>`,
        end: `</span>`,
      },
      "24pt": {
        start: `<span style='font-size: 24pt;'>`,
        end: `</span>`,
      },
      "32pt": {
        start: `<span style='font-size: 32pt;'>`,
        end: `</span>`,
      },
      GREYclr: {
        start: `<span style='color: #8B8B8B;'>`,
        end: `</span>`,
      },
      REDclr: {
        start: `<span style='color: #E51515;'>`,
        end: `</span>`,
      },
      ORANGEclr: {
        start: `<span style='color: #FCB104;'>`,
        end: `</span>`,
      },
      PURPLEclr: {
        start: `<span style='color: #A742FF;'>`,
        end: `</span>`,
      },
      GREENclr: {
        start: `<span style='color: #05C127;'>`,
        end: `</span>`,
      },
      BLUEclr: {
        start: `<span style='color: #0D73D9;'>`,
        end: `</span>`,
      },
    },

    blockToHTML: function (block) {
      const { margin, text } = getStyle(block);

      if (block.type === "left") {
        return {
          start: `<div style='text-align: left'>`,
          end: `</div>`,
        };
      }

      if (block.type === "center") {
        return {
          start: `<div style='text-align: center'>`,
          end: `</div>`,
        };
      }

      if (block.type === "right") {
        return {
          start: `<div style='text-align: right'>`,
          end: `</div>`,
        };
      }

      if (block.type === "PARAGRAPH") {
        return {
          start: "<p>",
          end: "</p>",
          empty: "<br/>",
        };
      }

      if (block.type === "code-block") {
        return {
          start: "<pre>",
          end: "</pre",
        };
      }

      if (block.type === "atomic") {
        return {
          start: "<div>",
          end: "</div>",
        };
      }

      return defaultBlock[block.type];
    },

    entityToHTML: (entity, originalText) => {
      if (entity.type === "MERGE_FIELD") {
        return `<span style="color: '#1d28fa'; font-weight: 700;">${originalText}</span>`;
      } else if (entity.type === "IMAGE") {
        let styles = {
          position: "relative",
          "margin-left": "8px",
          "margin-right": "8px",
        };

        const alignmentData = entity.data.alignment;

        if (alignmentData) {
          if (includes(["right", "left"], alignmentData)) {
            styles.float = alignmentData;
            styles["margin-right"] = alignmentData === "right" ? "24px" : styles["margin-right"];
          } else if (alignmentData === "center") {
            styles["margin-left"] = "auto";
            styles["margin-right"] = "auto";
            styles.display = "block";
          }
        }

        const widthConstant = 602; // This is the draft editor's editable content width
        const width = entity.data.width || 40;
        styles.width = `${widthConstant * (parseInt(width, 10) / 100)}px`;

        return `<img src="${entity.data.src}" style="${map(styles, (val, key) => `${key}: ${val}`).join(";")}" />`;
      } else {
        return originalText;
      }
    },
  })(content);

  return `
  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,300,400,600" type="text/css" media="all" />
  <style type="text/css" media="all">
    html {
      font-size: 62.5%;
      box-sizing: border-box;
    }
    body {
      margin: 0;
      padding: 0;
      font-size:10pt;
      line-height: 1.5;
      box-sizing: border-box;
      font-family: "Open Sans", arial, sans-serif;
    }
    p, div {
      margin: 0;
      min-height: 18px;
      box-sizing: border-box;
    }
    ol, ul {
      margin: 0;
    }
  </style>
  ${replaceCharacters(html)}`;
}

export function replaceCharacters(html) {
  const chars = [
    { input: /\r\n/g, output: "<br/>" },
    { input: /\n/g, output: "<br/>" },
    { input: /\r/g, output: "<br/>" },
    { input: /\t/g, output: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" },
    { input: /[ ]{2}/g, output: "&nbsp;&nbsp;" },
  ];

  return chars.reduce((html, char) => {
    return html.replace(char.input, char.output);
  }, html);
}

export function insertImage(action, editorState) {
  if (!action.encodedImage) {
    return editorState;
  }

  const imagePlugin = createImagePlugin();

  const stateWithImage = imagePlugin.addImage(editorState, action.encodedImage);
  const currentContent = stateWithImage.getCurrentContent();
  const key = stateWithImage.getSelection().getStartKey();

  let blockAfter, blockBefore;
  blockAfter = currentContent.getBlockAfter(key);
  if (!blockAfter) blockBefore = currentContent.getBlockBefore(key);
  const block = blockBefore ? blockBefore : blockAfter;

  const stateWithImageAbove = moveAtomicBlock(
    stateWithImage,
    block,
    stateWithImage.getSelection(),
    blockBefore ? "after" : "before"
  );

  return stateWithImageAbove;
}
