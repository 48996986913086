import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./client-request.actions.js";

import ClientRequestDueDate from "./client-request-due-date.component.js";
import ClientRequestHeader from "./client-request-header.component.js";
import ClientRequestReminders from "./client-request-reminders.component.js";
import ClientRequestDescription from "./client-request-description.component.js";

import styles from "./client-request.styles.css";
import { primaryNavHeightObs } from "primary-navbar!sofe";

@connect((state) => ({
  clientRequest: state.clientRequest,
}))
export default class ClientRequest extends React.Component {
  constructor(props) {
    super(props);
    this.boundActions = bindActionCreators(actions, props.dispatch);
    this.letterNavHeight = 100;

    this.state = {
      navHeight: 80,
    };
  }

  render() {
    const props = this.props;
    const boundActions = this.boundActions;

    function clickedSendLater() {
      boundActions.clickedSendLater.bind(null, props.params)(arguments);
      props.actions.closePreview(arguments);
    }

    function clickedCancel() {
      props.actions.closeClientRequestDialog(arguments);
      props.actions.closePreview(arguments);
    }

    let inlineStyles = {
      right: props.clientRequest.visible ? "0rem" : "-34.4rem",
      top: `${this.state.navHeight + this.letterNavHeight}px`,
      height: `calc(100% - ${this.state.navHeight + this.letterNavHeight}px)`,
    };

    return (
      <div>
        <div className={`${styles.sidePane}`} style={inlineStyles}>
          <div className={`cps-card ${styles.sidePaneContainer}`} style={{ borderRadius: "0" }}>
            <div className={`${styles.sidePaneHeader}`}>
              <div className="cps-col-xs-10">
                <ClientRequestHeader
                  actions={boundActions}
                  title={
                    props.clientRequest.requestTitle === null ? props.letterTitle : props.clientRequest.requestTitle
                  }
                />
              </div>
              <div className="cps-col-xs-2">
                <div className="cps-btn-icon">
                  <a onClick={clickedCancel} className="cps-link">
                    <span className="cps-icon cps-icon-close"></span>
                  </a>
                </div>
              </div>
            </div>
            <div id="cp-client-request-side-pane__content">
              <ClientRequestDueDate date={props.clientRequest.dueDate} actions={boundActions} />
              <ClientRequestReminders {...props} actions={boundActions} />
              <ClientRequestDescription {...props} actions={boundActions} />
            </div>
            <div className={`${styles.sendActions}`}>
              <button
                onClick={boundActions.clickedSendClientRequest.bind(null, props.params)}
                style={{ display: props.clientRequest.requestSent ? "none" : "inline-block" }}
                className="cps-btn +primary cps-padding-right-24"
              >
                Send now
              </button>
              <button
                onClick={clickedSendLater}
                style={{ display: props.clientRequest.requestSent ? "none" : "inline-block" }}
                className="cps-btn +secondary cps-padding-right-24"
              >
                Send later
              </button>

              <button
                onClick={boundActions.clickedUpdateClientRequest.bind(null, props.params)}
                style={{ display: !props.clientRequest.requestSent ? "none" : "inline-block" }}
                className="cps-btn +primary cps-padding-right-24"
              >
                Update
              </button>
              <button
                onClick={clickedCancel}
                style={{ display: !props.clientRequest.requestSent ? "none" : "inline-block" }}
                className="cps-btn +secondary cps-padding-right-24"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  componentDidMount() {
    setTimeout(this.boundActions.animateShowClientRequest.bind(null, "visible"), 50);
    this.boundActions.getClientCollaborators(this.props.params.clientId);

    this.navbarDisposable = primaryNavHeightObs.subscribe((height) => {
      this.setState({ navHeight: height });
    });

    this.boundActions.animateShowClientRequest("hidden");

    if (this.props.requestId) {
      this.boundActions.loadClientRequest(
        this.props.params.clientId,
        this.props.params.resolutionCaseId,
        this.props.requestId
      );
    } else {
      this.boundActions.loadEmptyClientRequest();
    }
  }

  componentWillUnmount() {
    if (this.navBarDisposable) {
      this.navbarDisposable.unsubscribe();
    }

    this.boundActions.animateShowClientRequest("hidden");
  }
}
