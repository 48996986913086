import React from "react";
import { CpTooltip } from "canopy-styleguide!sofe";
import styles from "../editor/editor.styles.css";
import { click } from "../common/common.utils.js";

export default function InsertTextCategory({
  categoryName,
  list,
  categoryKey,
  insertFunction,
  editorInstance,
  isActiveCategory,
  setActiveCategory,
  mergeFields,
}) {
  return (
    <div className="cps-row cps-padding-top-4 cps-padding-left-0">
      <div
        style={{ cursor: "pointer" }}
        onMouseDown={click.bind(null, setActiveCategory.bind(null, isActiveCategory ? null : categoryKey))}
      >
        <div className="cps-col-xs-10 cps-gray-10 cps-caption">{categoryName}</div>
        <div className="cps-col-xs-2">
          <i className={`cps-icon ${isActiveCategory ? "cps-icon-sm-caret-up" : "cps-icon-sm-caret-down"}`}></i>
        </div>
      </div>
      {isActiveCategory ? buildItemsToDisplay(list, editorInstance, insertFunction, mergeFields) : null}
    </div>
  );
}

function buildItemsToDisplay(list, editorInstance, insertFunction, mergeFields) {
  let orderedList = list.sort((a, b) => a.title.localeCompare(b.title));

  return orderedList.map((item) => {
    if (item.tooltip) {
      return item.hidden ? null : (
        <CpTooltip key={item.key} text={item.tooltip} delay={100} disabled={false}>
          <div className="cps-row cps-padding-top-4">
            <a
              onMouseDown={click.bind(
                null,
                insertFunction.bind(
                  null,
                  item.mergedValue ? item.mergedValue : item.insertText,
                  editorInstance,
                  mergeFields
                )
              )}
              className={`${styles.insertBoilerplate}`}
            >
              <i className={`cps-icon cps-icon-quick-add cps-color-primary cps-margin-right-4 ${styles.hoverShow}`}>
                <span className={`cps-caption ${styles.insertBoilerplate}`}>{item.title}</span>
              </i>
            </a>
          </div>
        </CpTooltip>
      );
    } else {
      return item.hidden ? null : (
        <div key={item.key} className="cps-row cps-padding-top-4">
          <a
            onMouseDown={click.bind(null, insertFunction.bind(null, item, editorInstance, mergeFields))}
            className={styles.insertBoilerplate}
          >
            <i className={`cps-icon cps-icon-quick-add cps-color-primary cps-margin-right-4 ${styles.hoverShow}`}>
              <span className={`cps-caption ${styles.insertBoilerplate}`}>{item.title}</span>
            </i>
          </a>
        </div>
      );
    }
  });
}
