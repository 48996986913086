import * as types from "./publish.types";
import { DOCUMENT_LOADED } from "../editor.types";

const DEFAULT_STATE = {
  description: "",
  service_slugs: [],
  submitting: false,
};
export default function publishReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case types.UPDATE_CANOPY_TEMPLATE_DESCRIPTION:
      return { ...state, description: action.description };
    case types.UPDATE_CANOPY_TEMPLATE_SERVICE_SLUGS:
      return { ...state, service_slugs: action.service_slugs };
    case DOCUMENT_LOADED:
      const { service_slugs, description } = action.document;
      return { ...state, service_slugs, description, submitting: false };
    case types.SUBMITTING:
      return { ...state, submitting: true };
    default:
      return state;
  }
}
