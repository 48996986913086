import canopyUrls from "canopy-urls!sofe";
import { fetchAsObservable } from "fetcher!sofe";

export function getBoilerplateItems() {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/templates/boilerplate_categories`);
}

export function postBoilerplateItem(boilerplate) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/templates/boilerplates`, {
    method: "POST",
    body: `{"boilerplates": ${JSON.stringify(boilerplate)}}`,
  });
}

export function putBoilerplateItem(boilerplate) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/templates/boilerplates/${boilerplate.id}`, {
    method: "PUT",
    body: `{"boilerplates": ${JSON.stringify(boilerplate)}}`,
  });
}

export function deleteBoilerplateItem(boilerplate) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/templates/boilerplates/${boilerplate.id}`, {
    method: "DELETE",
  });
}

export function postBoilerplateCategory(category) {
  category = {
    ...category,
    body: "",
    relationships: { boilerplates: [] },
  };

  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/templates/boilerplate_categories`, {
    method: "POST",
    body: `{"boilerplate_categories": ${JSON.stringify(category)}}`,
  });
}

export function putBoilerplateCategory(category) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/templates/boilerplate_categories/${category.id}`, {
    method: "PUT",
    body: `{"boilerplate_categories": ${JSON.stringify(category)}}`,
  });
}

export function deleteBoilerplateCategory(category) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/templates/boilerplate_categories/${category.id}`, {
    method: "DELETE",
  });
}
