import React from "react";
import styles from "./editor.styles.css";

export default (config = {}) => {
  const TokenSpan = (props) => {
    let entity = props.contentState.getEntity(props.entityKey);
    return (
      <span data-merge-field={entity.get("data").field.key} className={styles.crmToken}>
        {props.children}
      </span>
    );
  };

  function getEntityStrategy(type) {
    return function (contentBlock, callback, contentState) {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        if (entityKey === null) {
          return false;
        }
        return contentState.getEntity(entityKey).getType() === type;
      }, callback);
    };
  }

  return {
    decorators: [
      {
        strategy: getEntityStrategy("MERGE_FIELD"),
        component: TokenSpan,
      },
    ],
  };
};
