import React from "react";

import { shareLetter } from "./share-letter.component.helper.js";

export default function ShareLetter({
  actions,
  shareWithClientMenuDisplayed = false,
  clientId,
  resolutionCaseId,
  letterId,
  loggedInUser,
}) {
  function clickedShareAsClientRequest() {
    actions.loadPreview.bind(null, { clientId, resolutionCaseId, letterId })(arguments);
    actions.toggleClientRequestDialog.bind(null)(arguments);
  }

  return (
    <div className={`cps-dropdown cps-pull-right ${shareWithClientMenuDisplayed ? "cps-open" : ""}`}>
      <a id="shareLetterButton" className="cps-link cps-color-primary" style={{ marginTop: 0 }}>
        Send to:
      </a>
      <ul className="cps-dropdown-menu cps-pull-right" role="menu">
        <li>
          <a onClick={shareLetter.bind(null, clientId, letterId, loggedInUser.id)}>Client files</a>
        </li>
        <li>
          <a onClick={clickedShareAsClientRequest}>Client request</a>
        </li>
      </ul>
    </div>
  );
}
