// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-editor-canopy-template-publish-status-styles__editorPublishStatus{\n  font-size: 1.3rem;\n  margin-right: 8px;\n}\n.src-editor-canopy-template-publish-status-styles__editorPublishStatus::before { \n  color: currentColor;\n}", ""]);
// Exports
exports.locals = {
	"editorPublishStatus": "src-editor-canopy-template-publish-status-styles__editorPublishStatus"
};
module.exports = exports;
