import React from "react";
import PropTypes from "prop-types";

import { click } from "../common/common.utils.js";

import { primaryNavHeightObs } from "primary-navbar!sofe";

import styles from "./flash-icon.styles.css";

export default class FlashIcon extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    flashIconDisplayed: PropTypes.bool.isRequired,
    flashMenuDisplayed: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      navHeight: 80,
    };
  }

  render() {
    return this.props.flashIconDisplayed ? (
      <div style={{ position: "relative", left: "-11.25rem" }}>
        <div
          ref={(el) => {
            this.flashIconContainer = el;
          }}
          className={`cps-dropdown ${this.props.flashMenuDisplayed ? "cps-open" : ""} flashIcon`}
          style={{ transition: "top 200ms", position: "absolute", top: "336px" }}
        >
          <div className="cps-btn-icon">
            <a className="cps-link" style={{ background: "none" }}>
              <span
                id="flashIcon"
                data-testid="test-flashIcon"
                className={`cps-icon +round cps-bg-primary cps-white cps-icon-fast ${styles.flashIconEmbiggened}`}
              ></span>
            </a>
          </div>
          <ul className="cps-dropdown-menu" role="menu" data-testid="test-flashMenuDisplayed">
            <li>
              <a onMouseDown={click.bind(null, this.props.actions.setLeftToolMenuType.bind(null, "client-attributes"))}>
                Add Attribute
              </a>
            </li>
            <li>
              <a onMouseDown={click.bind(null, this.props.actions.setLeftToolMenuType.bind(null, "boilerplate"))}>
                Add Boilerplate Text
              </a>
            </li>
          </ul>
        </div>
      </div>
    ) : null;
  }

  componentDidUpdate() {
    determineIconPlacement(this.props.flashIconPlacementHelperData, this.flashIconContainer, this.state.navHeight);
  }

  componentDidMount() {
    this.navbarDisposable = primaryNavHeightObs.subscribe((height) => {
      this.setState({ navHeight: height });
    });
  }

  componentWillUnmount() {
    if (this.navBarDisposable) {
      this.navbarDisposable.unsubscribe();
    }
  }
}

export function determineIconPlacement(flashIconPlacementHelperData, flashEl, navHeight = 80) {
  if (flashIconPlacementHelperData && flashEl) {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

    const top = Math.floor(flashIconPlacementHelperData.top - (224 + navHeight)) + scrollTop;
    flashEl.style.top = `${top + flashIconPlacementHelperData.height / 2}px`;
  }
}
