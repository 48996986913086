// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* client-request-header.style.css */\n\n.src-client-request-client-request-header-styles__clientRequestHeaderTitle {\n  padding: 0;\n  height: 3.2rem;\n  max-height: 19rem;\n  font-size: 1.6rem;\n  outline: none;\n  border: none;\n  resize: none;\n  width: 100%;\n  margin-left: -8px;\n}", ""]);
// Exports
exports.locals = {
	"clientRequestHeaderTitle": "src-client-request-client-request-header-styles__clientRequestHeaderTitle"
};
module.exports = exports;
